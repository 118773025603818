import React, { Component } from "react";
import { Container } from "semantic-ui-react";
import { Switch, Route, Redirect } from "react-router-dom";
import BudgetHeaderContent from "./BudgetHeaderContent";

class BudgetHeader extends Component {
  render() {
    const { general, onCompareChange, openIncomeModal } = this.props;

    return (
      <Container className="standardSeparation">
        <Switch>
          <Redirect exact from="/budget" to="/budget/budget" />
          <Route
            path="/budget/budget"
            render={() => (
              <BudgetHeaderContent
                general={general}
                compareChange={onCompareChange}
                openIncomeModal={openIncomeModal}
              />
            )}
          />
        </Switch>
      </Container>
    );
  }
}

export default BudgetHeader;
