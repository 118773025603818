import React from "react";
import { connect } from "react-redux";
import RoadmapModal from "./RoadmapModal";
import LoginModal from "./LoginModal";
import RegisterModal from "./RegisterModal";
import BudgetDeleteModal from "./BudgetDeleteModal";
import BudgetAddModal from "./BudgetAddModal";
import BudgetCompareModal from "./BudgetCompareModal";
import BudgetIdeaModal from "./BudgetIdeaModal";
import IncomeModal from "./IncomeModal";
import EditSavingsItemModal from "./EditSavingsItemModal";
import AddSavingsItemModal from "./AddSavingsItemModal";
import EditDebtItemModal from "./EditDebtItemModal";
import AddDebtItemModal from "./AddDebtItemModal";

const modalLookup = {
  RoadmapModal,
  LoginModal,
  RegisterModal,
  BudgetDeleteModal,
  BudgetAddModal,
  BudgetCompareModal,
  BudgetIdeaModal,
  IncomeModal,
  EditSavingsItemModal,
  AddSavingsItemModal,
  EditDebtItemModal,
  AddDebtItemModal
};

const mapState = state => ({
  currentModal: state.modals
});

const ModalManager = ({ currentModal }) => {
  let renderedModal;

  if (currentModal) {
    const { modalType, modalProps } = currentModal;
    const ModalComponent = modalLookup[modalType];

    renderedModal = <ModalComponent {...modalProps} />;
  }

  return <span>{renderedModal}</span>;
};

export default connect(mapState)(ModalManager);
