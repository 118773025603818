import React, { Component } from "react";
import { Modal, Form, Button } from "semantic-ui-react";
import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import { closeModal } from "./modalActions";
import { editSavingsItem } from "../budget/budgetActions";
import TextInput from "../../app/common/form/TextInput";
import { createNumberMask } from "redux-form-input-masks";
import SelectInput from "../../app/common/form/SelectInput";
import budgetAddItems from "../../app/common/BudgetAddModalItems";

const currencyMask = createNumberMask({
  prefix: "$",
  decimalPlaces: 2,
  locale: "en-US"
});

// Set up titlesSelection for dropdown
let titlesSelection = [];
let titles = [].concat.apply([], Object.values(budgetAddItems)).map(rec => rec["title"]);
let uniqueTitles = [... new Set(titles)];
uniqueTitles.forEach(title => {
    titlesSelection.push({"key": title, "text": title, "value": title})
})

const actions = {
  closeModal,
  editSavingsItem
};

class AddSavingsItemModal extends Component {
  updateSavingsItem = formVals => {
    this.props.editSavingsItem(
      formVals.savingsItemNewTitle,
      formVals.savingsItemNewVal,
      this.props.authUID
    );
    this.props.closeModal();
  };

  render() {
    return (
      <Modal size="small" open={true} onClose={this.props.closeModal}>
        <Modal.Header>Edit Savings Item</Modal.Header>
        <Modal.Content>
          <Form onSubmit={this.props.handleSubmit(this.updateSavingsItem)}>
            <Form.Field>
              <label>Choose Savings Item</label>
              <Field
                name="savingsItemNewTitle"
                type="text"
                component={SelectInput}
                options={titlesSelection}
              />
            </Form.Field>
            <Form.Field>
              <label>Current Value</label>
              <Field
                name="savingsItemNewVal"
                type="text"
                component={TextInput}
                {...currencyMask}
              />
            </Form.Field>
            <Button type="submit">Add</Button>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}

export default connect(
  null,
  actions
)(reduxForm({ form: "editSavingsItemForm" })(AddSavingsItemModal));
