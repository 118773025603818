import React from "react";
import { Grid, Menu, Icon } from "semantic-ui-react";
import { NavLink, withRouter } from "react-router-dom";

const ProfileNav = () => {
  return (
    <Grid.Column width={16}>
      <Menu horizontal="true" secondary>
        <Menu.Item as={NavLink} to="/profile/overview">
          <Icon name="money" color="green" size="big" />
          Overview
        </Menu.Item>
        <Menu.Item as={NavLink} to="/profile/analytics">
          <Icon className="mainTheme" name="pie graph" size="big" />
          Analytics
        </Menu.Item>
        <Menu.Item as={NavLink} to="/profile/compare">
          <Icon className="mainTheme" name="group" size="big" />
          Compare
        </Menu.Item>
      </Menu>
    </Grid.Column>
  );
};

export default withRouter(ProfileNav);
