import React, { Component } from "react";
import { Container } from "semantic-ui-react";
import { Switch, Route, Redirect } from "react-router-dom";
import BudgetListContent from "./BudgetListContent";
import BudgetVariableAllocations from "./BudgetVariableAllocations";
import BudgetSavings from "./BudgetSavings";
import BudgetDebt from "./BudgetDebt";

class BudgetList extends Component {
  render() {
    const {
      updateData,
      deleteData,
      addData,
      data,
      general,
      unallocatedBudget,
      unallocatedSpending,
      openCompareModal,
      openIdeaModal,
      removeSavingsItem,
      editSavingsItem,
      addSavingsItem,
      removeDebtItem,
      editDebtItem,
      addDebtItem
    } = this.props;

    return (
      <Container className="standardSeparation">
        <Switch>
          <Redirect exact from="/budget" to="/budget/budget" />
          <Route
            path="/budget/budget"
            render={() => (
              <BudgetListContent
                data={data}
                unallocatedBudget={unallocatedBudget}
                unallocatedSpending={unallocatedSpending}
                updateData={updateData}
                deleteData={deleteData}
                addData={addData}
                openCompareModal={openCompareModal}
                openIdeaModal={openIdeaModal}
              />
            )}
          />
          <Route
            path="/budget/variable_allocations"
            render={() => <BudgetVariableAllocations data={data} />}
          />
          <Route
            path="/budget/savings"
            render={() => (
              <BudgetSavings
                data={data}
                general={general}
                removeSavingsItem={removeSavingsItem}
                editSavingsItem={editSavingsItem}
                addSavingsItem={addSavingsItem}
              />
            )}
          />
          <Route
            path="/budget/debt"
            render={() => (
              <BudgetDebt
                data={data}
                general={general}
                removeDebtItem={removeDebtItem}
                editDebtItem={editDebtItem}
                addDebtItem={addDebtItem}
              />
            )}
          />
        </Switch>
      </Container>
    );
  }
}

export default BudgetList;
