import React, { Component } from "react";
import { connect } from "react-redux";
import { withFirebase } from "react-redux-firebase";
import { Menu, Container, Button, Icon } from "semantic-ui-react";
import { NavLink, Link, withRouter } from "react-router-dom";
import "semantic-ui-css/semantic.min.css";

class NavBar extends Component {
  handleSignOut = () => {
    this.props.firebase.logout();
  }

  render() {
    return (
      <Menu inverted fixed="top">
        <Container>
          <Menu.Item>
            <Icon name="angle double up" size="big" />
            Atlas
          </Menu.Item>
          <Menu.Item as={NavLink} to="/profile" name="Profile" />
          <Menu.Item as={NavLink} to="/budget" name="Budget" />
          <Menu.Item position="right">
            <Button
              as={Link}
              to="/proSignUp"
              className="complimentBkgTheme contrastTheme"
              animated="fade"
            >
              <Button.Content visible>Sign-up for a Pro Account</Button.Content>
              <Button.Content hidden>$5 a month</Button.Content>
            </Button>
            <Button
              as={Link}
              to="/settings"
              className="complimentBkgTheme contrastTheme"
              animated="vertical"
            >
              <Button.Content hidden>Settings</Button.Content>
              <Button.Content visible>
                <Icon name="setting" />
              </Button.Content>
            </Button>
            <Button
              as={Link}
              to="/"
              className="complimentBkgTheme contrastTheme"
              animated="vertical"
              onClick={this.handleSignOut}
            >
              <Button.Content hidden>Log Out</Button.Content>
              <Button.Content visible>
                <Icon name="sign out" />
              </Button.Content>
            </Button>
          </Menu.Item>
        </Container>
      </Menu>
    );
  }
}

export default withRouter(
  withFirebase(
    connect(
      null
    )(NavBar)
  )
);
