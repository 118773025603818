import React from "react";
import { Button, Icon } from "semantic-ui-react";

const SocialCreateAccount = ({ clickedItem }) => {
    return (
        <div>
            <Button
                type="button"
                color="facebook"
                style={{ float: "left" }}
                onClick={() => clickedItem("facebook")}
            >
                <Icon name="facebook"/>
                Create account with Facebook
            </Button>

            <Button
                type="button"
                color="google plus"
                style={{ float: "left" }}
                onClick={() => clickedItem("google")}
            >
                <Icon name="google plus"/>
                Create account with Google Plus
            </Button>
        </div>
    );
};

export default SocialCreateAccount;