import { SubmissionError } from "redux-form";
import { closeModal } from "../modals/modalActions";

export const login = (creds, history) => {
  return async (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    try {
      await firebase
        .auth()
        .signInWithEmailAndPassword(creds.email, creds.password);
      dispatch(closeModal);
      history.push("/profile");
    } catch (error) {
      throw new SubmissionError({
        _error: "Login Failed"
      });
    }
  };
};

export const registerUser = (user, history) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    try {
      let createdUser;
      if (user.useFacebookLogin) {
        createdUser = await firebase.login({
          provider: "facebook",
          type: "popup"
        });
      } else if (user.useGoogleLogin) {
        createdUser = await firebase.login({
          provider: "google",
          type: "popup"
        });
      } else {
        // create the user in auth
        createdUser = await firebase
          .auth()
          .createUserWithEmailAndPassword(user.email, user.password)
          .catch(function(error) {
            console.log("Create New User Error: " + error);
          });
      }
      // update the auth profile
      //await createdUser.updateProfile({
      //displayName: "TEST DISPLAY NAME"
      //});
      // create a new profile in firestore
      let newUser = {
        displayName: user.fullname || "Unknown user",
        photoURL: "https://example.com/jane-q-user/profile.jpg",
        createdAt: firestore.FieldValue.serverTimestamp()
      };
      // create specific budget data for user
      const housingBudgeted = 0;
      const housingSpent = 0;
      const housingLeftover = 0;

      const generalLivingBudgeted = 0;
      const generalLivingSpent = 0;
      const generalLivingLeftover = 0;

      const investmentsBudgeted = 0;
      const investmentsSpent = 0;
      const investmentsLeftover = 0;

      const indulgencesBudgeted = 0;
      const indulgencesSpent = 0;
      const indulgencesLeftover = 0;

      let nodeData = {
        id: createdUser.user.uid,
        time: "Month",
        content: [
          {
            atlasScore: Math.floor(Math.random() * 100) + 1,
            budgeted: housingBudgeted,
            budgetedPercent: (housingBudgeted / (user.grossIncome / 12)) * 100,
            icon: "home",
            id: 1,
            leftover: housingLeftover,
            spent: housingSpent,
            title: "Housing",
            type: "housing",
            sections: []
          },
          {
            atlasScore: Math.floor(Math.random() * 100) + 1,
            budgeted: generalLivingBudgeted,
            budgetedPercent:
              (generalLivingBudgeted / (user.grossIncome / 12)) * 100,
            icon: "balance scale",
            id: 2,
            leftover: generalLivingLeftover,
            spent: generalLivingSpent,
            title: "General Living",
            type: "generalLiving",
            sections: []
          },
          {
            atlasScore: Math.floor(Math.random() * 100) + 1,
            budgeted: investmentsBudgeted,
            budgetedPercent:
              (investmentsBudgeted / (user.grossIncome / 12)) * 100,
            icon: "money",
            id: 3,
            leftover: investmentsLeftover,
            spent: investmentsSpent,
            title: "Investments",
            type: "investments",
            sections: []
          },
          {
            atlasScore: Math.floor(Math.random() * 100) + 1,
            budgeted: indulgencesBudgeted,
            budgetedPercent:
              (indulgencesBudgeted / (user.grossIncome / 12)) * 100,
            icon: "glass martini",
            id: 4,
            leftover: indulgencesLeftover,
            spent: indulgencesSpent,
            title: "Indulgences",
            type: "indulgences",
            sections: []
          }
        ]
      };
      // end create specific budget data for user
      let general = {
        grossIncome: user.grossIncome,
        netIncome: user.netIncome,
        percentGeneralLiving: 0,
        percentHousing: 0,
        percentIndulgences: 0,
        percentInvestments: 0,
        unallocatedBudget: user.netIncome / 12,
        unallocatedSpent: user.netIncome / 12,
        incomeSources: [{
          gross: user.grossIncome,
          id: 1,
          net: user.netIncome,
          source: "unknown"
        }],
        debtItems: {},
        debtTotal: 0,
        savingItems: {},
        savingsTotal: 0,
        netWorth: 0
      };

      let today = new Date();
      let currentYear = today.getFullYear();
      let goals = {
        assets: {
          [currentYear]: 100
        },
        debts: {
          [currentYear]: 0
        },
        income: {
          [currentYear]: user.grossIncome + 1000
        },
        netWorth: {
          [currentYear]: 1000
        },
        savings: {
          [currentYear]: 900
        }
      }

      // create profile overview 
      let ageDif = Date.now() - user.date.toDate().getTime();
      let ageDate = new Date(ageDif); // miliseconds from epoch
      let age = Math.abs(ageDate.getUTCFullYear() - 1970);
      let profileOverview = {
        age: age,
        grossIncome: user.grossIncome,
        netIncome: user.netIncome,
        location: user.location,
        name: user.fullname,
        occupation: user.occupation,
        sex: user.sex,
        assets: 0,
        debts: 0,
        netWorth: 0,
        savings: 0
      };
      // end create profile overview
      await firestore.set(`users/${createdUser.user.uid}`, { ...newUser });
      await firestore.set(`users/${createdUser.user.uid}/budget/data`, {
        ...nodeData
      });
      await firestore.set(`users/${createdUser.user.uid}/budget/general`, {
        ...general
      });
      await firestore.set(`users/${createdUser.user.uid}/profile/overview`, {
        ...profileOverview
      });
      await firestore.set(`users/${createdUser.user.uid}/profile/goals`, {
        ...goals
      });
      await firestore.set(`users/${createdUser.user.uid}/profile/archive`, {
        ...{}
      });
      dispatch(closeModal);
      history.push("/profile");
    } catch (error) {
      throw new SubmissionError({
        _error: error
      });
    }
  };
};

export const socialLogin = (selectedProvider, history) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    try {
      let user = await firebase.login({
        provider: selectedProvider,
        type: "popup"
      });
      // if the social login has not already been registered, delete data
      if (user.additionalUserInfo.isNewUser) {
        firestore
          .collection("users")
          .doc(user.user.uid)
          .delete();
        firebase.auth().currentUser.delete();
        throw new SubmissionError({
          _error:
            "Need to register with this" + selectedProvider + "account first"
        });
      }
      dispatch(closeModal());
      history.push("/profile");
      await firestore.set(`users/${user.user.uid}`, {
        displayName: user.profile.displayName,
        photoURL: user.profile.avatarUrl,
        createdAt: firestore.FieldValue.serverTimestamp()
      });
    } catch (error) {
      throw new SubmissionError({
        _error: "Login Error"
      });
    }
  };
};
