import React from "react";

const ProSignUp = () => {
  return (
    <div>
      <h1>Pro Account Sign Up Page</h1>
    </div>
  );
};

export default ProSignUp;
