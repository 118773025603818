const budgetAddItems = {
    housing: [
        {
            title: "Rent",
            icon: "building",
            default: []
        },
        {
            title: "Mortgage",
            icon: "home",
            default: []
        },
        {
            title: "Gas",
            icon: "tint",
            default: ["variable"]
        },
        {
            title: "Electricity",
            icon: "lightbulb",
            default: ["variable"]
        },
        {
            title: "Internet",
            icon: "wifi",
            default: []
        },
        {
            title: "Water",
            icon: "water",
            default: ["variable"]
        },
        {
            title: "Maintenance",
            icon: "tools",
            default: ["variable"]
        },
        {
            title: "Insurance",
            icon: "lock",
            default: []
        },
        {
            title: "Parking",
            icon: "parking",
            default: []
        },
        {
            title: "Laundry",
            icon: "tshirt",
            default: ["variable"]
        },
        {
            title: "Property Taxes",
            icon: "hand-holding-usd",
            default: []
        },
        {
            title: "Landscaping",
            icon: "cut",
            default: ["variable"]
        },
        {
            title: "Cleaning",
            icon: "broom",
            default: ["variable"]
        },
        {
            title: "Home Improvements",
            icon: "snowplow",
            default: ["variable", "savings"]
        },
        {
            title: "Unexpected Costs",
            icon: "exclamation-triangle",
            default: ["variable"]
        },
        {
            title: "Other",
            icon: "question",
            default: ["variable"]
        },
    ],
    generalLiving: [
        {
            title: "Groceries",
            icon: "shopping-cart",
            default: ["variable"]
        },
        {
            title: "Gas (Car)",
            icon: "gas-pump",
            default: ["variable"]
        },
        {
            title: "Car Repairs",
            icon: "tools",
            default: ["variable", "savings"]
        },
        {
            title: "Car Payments",
            icon: "car",
            default: []
        },
        {
            title: "Haircut",
            icon: "cut",
            default: ["variable"]
        },
        {
            title: "Phone Bill",
            icon: "mobile",
            default: []
        },
        {
            title: "Transportation",
            icon: "bus",
            default: ["variable"]
        },
        {
            title: "Medical Care",
            icon: "user-md",
            default: []
        },
        {
            title: "Medical Insurance",
            icon: "capsules",
            default: []
        },
        {
            title: "Child Support",
            icon: "baby",
            default: ["variable"]
        },
        {
            title: "Babysitter",
            icon: "baby-carriage",
            default: ["variable"]
        },
        {
            title: "Education",
            icon: "school",
            default: ["variable"]
        },
        {
            title: "Company Meal Plan",
            icon: "carrot",
            default: []
        },
        {
            title: "Toiletries",
            icon: "toilet-paper",
            default: ["variable"]
        },
        {
            title: "Miscellaneous",
            icon: "random",
            default: ["variable"]
        },
        {
            title: "Other",
            icon: "question",
            default: ["variable"]
        },
        {
            title: "Car",
            icon: "car",
            default: []
        }
    ],
    investments: [
        {
            title: "Savings",
            icon: "money-bill-alt",
            default: ["savings"]
        },
        {
            title: "401K",
            icon: "university",
            default: ["savings", "preTax"]
        },
        {
            title: "Roth 401k",
            icon: "money-bill-alt",
            default: ["savings"]
        },
        {
            title: "HRA",
            icon: "money-bill-alt",
            default: ["savings", "preTax"]
        },
        {
            title: "IRA",
            icon: "money-bill-alt",
            default: ["savings"]
        },
        {
            title: "Roth IRA",
            icon: "money-bill-alt",
            default: ["savings"]
        },
        {
            title: "Individual Stock Picks",
            icon: "money-bill-alt",
            default: ["savings", "variable"]
        },
        {
            title: "Funds",
            icon: "money-bill-alt",
            default: ["savings", "variable"]
        },
        {
            title: "Real Estate",
            icon: "money-bill-alt",
            default: ["savings", "variable"]
        },
        {
            title: "Cryptocurrency",
            icon: "money-bill-alt",
            default: ["savings", "variable"]
        },
        {
            title: "Options",
            icon: "money-bill-alt",
            default: ["variable"]
        },
        {
            title: "Self",
            icon: "user",
            default: ["variable"]
        },
        {
            title: "Other",
            icon: "question",
            default: ["savings", "variable"]
        },
    ],
    indulgences: [
        {
            title: "Eating Out",
            icon: "utensils",
            default: ["variable"]
        },
        {
            title: "Fun/Hobbies",
            icon: "bowling-ball",
            default: ["savings"]
        },
        {
            title: "Vacation",
            icon: "snowboarding",
            default: ["savings"]
        },
        {
            title: "Video Services",
            icon: "video",
            default: ["variable"]
        },
        {
            title: "Music Services",
            icon: "music",
            default: ["variable"]
        },
        {
            title: "Clothing",
            icon: "tshirt",
            default: ["variable"]
        },
        {
            title: "Videogames",
            icon: "gamepad",
            default: ["variable"]
        },
        {
            title: "Movies",
            icon: "theater-masks",
            default: ["variable"]
        },
        {
            title: "Other",
            icon: "question",
            default: ["variable"]
        },
    ]
};

export default budgetAddItems;