import React, { Component } from "react";
import { Modal, Button } from "semantic-ui-react";
import { connect } from "react-redux";

import { closeModal } from "./modalActions";
import { removeBudgetRecord } from "../budget/budgetActions";

const actions = { closeModal, removeBudgetRecord };

class BudgetDeleteModal extends Component {

  deleteBudgetRecord = () => {
    this.props.closeModal();
    this.props.removeBudgetRecord(
      this.props.headerId,
      this.props.itemId,
      this.props.authUID
    );
  }

  render() {
    return (
      <Modal size="mini" open={true} onClose={this.props.closeModal}>
        <Modal.Header>
          Are you sure you want to delete the {this.props.title.toUpperCase()}{" "}
          record?
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Button
              onClick={this.deleteBudgetRecord}
              color="red"
            >
              Delete
            </Button>
            <Button onClick={this.props.closeModal}>Cancel</Button>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

export default connect(
  null,
  actions
)(BudgetDeleteModal);
