import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Container } from "semantic-ui-react";
import ProfileList from "./ProfileList/ProfileList";
import ProfileAnalytics from "./ProfileAnalytics/ProfileAnalytics";
import ProfileCompare from "./ProfileCompare/ProfileCompare";

class ProfileFooter extends Component {
  render() {
    return (
      <Container className="standardSeparation">
        <Switch>
          <Redirect exact from="/profile" to="/profile/overview" />
          <Route path="/profile/overview" component={ProfileList} />
          <Route path="/profile/analytics" component={ProfileAnalytics} />
          <Route path="/profile/compare" component={ProfileCompare} />
        </Switch>
      </Container>
    );
  }
}

export default ProfileFooter;
