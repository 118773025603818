import React, { Component } from "react";
import { Modal, Form, Button } from "semantic-ui-react";
import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import { closeModal } from "./modalActions";
import { editSavingsItem } from "../budget/budgetActions";
import TextInput from "../../app/common/form/TextInput";
import { createNumberMask } from "redux-form-input-masks";

const currencyMask = createNumberMask({
  prefix: "$",
  decimalPlaces: 2,
  locale: "en-US"
});

const actions = {
  closeModal,
  editSavingsItem
};

class EditSavingsItemModal extends Component {
  updateSavingsItem = formVals => {
    this.props.editSavingsItem(
      this.props.title,
      formVals.savingsItemNewVal,
      this.props.authUID
    );
    this.props.closeModal();
  };

  render() {
    return (
      <Modal size="small" open={true} onClose={this.props.closeModal}>
        <Modal.Header>Edit Savings Item</Modal.Header>
        <Modal.Content>
          <Form onSubmit={this.props.handleSubmit(this.updateSavingsItem)}>
            <Form.Field>
              <label>New Value</label>
              <Field
                name="savingsItemNewVal"
                type="text"
                component={TextInput}
                {...currencyMask}
              />
            </Form.Field>
            <Button type="submit">Update</Button>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}

export default connect(
  null,
  actions
)(reduxForm({ form: "editSavingsItemForm" })(EditSavingsItemModal));
