import React, { Component } from "react";
import {
  Segment,
  Grid,
  Divider,
  Button,
  Header,
  Icon
} from "semantic-ui-react";
import { USDConverter } from "../../../app/common/util/Currency";
import { determineAtlasScoreColor } from "../../../app/common/util/AtlasScore";
import { Pie } from "react-chartjs-2";
import { RandomRGBColorArray } from "../../../app/common/util/Chart";

class BudgetSavings extends Component {
  updateToCurrency = (val, isString) => {
    return USDConverter(val, isString);
  };

  setColor = atlasScore => {
    return determineAtlasScoreColor(atlasScore);
  };

  render() {
    const {
      data,
      general,
      removeSavingsItem,
      editSavingsItem,
      addSavingsItem
    } = this.props;

    // Array of saving items
    let savingItems = general.savingItems;

    // Map of item title to all of its data contents
    data.content.unshift({});
    let itemData = data.content.reduce(function(map, rec) {
      map[rec.type] = rec.sections.filter(sec => sec.types.savings === true);
      return map;
    });
    data.content.shift();
    let itemMap = Object.values(itemData)
      .flat()
      .reduce((map, item) => {
        map[item.title] = item;
        return map;
      }, {});

    // Chart setup
    let pieChartColorScheme = RandomRGBColorArray(
      Object.keys(savingItems).length
    );
    let pieChartData = {
      labels: Object.keys(savingItems),
      datasets: [
        {
          data: Object.values(savingItems),
          backgroundColor: pieChartColorScheme,
          hoverBackgroundColor: pieChartColorScheme
        }
      ]
    };
    let pieChartOptions = {
      legend: {
        display: true,
        position: "bottom"
      }
    };

    let content = [];
    // combine all data into one array of hashes
    [].concat.apply([], Object.keys(savingItems)).forEach((rec, i) => {
      content.push(
        <Segment
          key={i}
          className="savingsItemContainer"
          color={this.setColor(itemMap[rec] ? itemMap[rec].atlasScore : 50)}
        >
          <h3
            style={{
              float: "right",
              color: this.setColor(itemMap[rec] ? itemMap[rec].atlasScore : 50)
            }}
          >
            {itemMap[rec] ? itemMap[rec].atlasScore : 50}
          </h3>
          <h2 className="savingsItemHeader">{rec}</h2>
          <h3
            style={{
              float: "left",
              marginRight: "5px",
              marginTop: "0",
              marginBottom: "0"
            }}
          >
            {this.updateToCurrency(general.savingItems[rec], false)}
          </h3>
          <h3 className="savingsCurrency">
            (+
            {this.updateToCurrency(
              itemMap[rec] ? itemMap[rec].budgeted : 0,
              false
            )}
            )
          </h3>
          <Divider />
          <Grid>
            <Grid.Row columns={2} className="savingsItemSubSectionRow">
              <Grid.Column className="savingsItemSubSection">
                <Segment>
                  <h5>This Months Additions</h5>
                  <p className="savingsCurrency savingsInnerNodeCurrency">
                    {this.updateToCurrency(
                      itemMap[rec] ? itemMap[rec].spent : 0,
                      false
                    )}
                  </p>
                </Segment>
              </Grid.Column>
              <Grid.Column className="savingsItemSubSection">
                <Segment>
                  <h5>Projected Yearly Additions</h5>
                  <p className="savingsCurrency savingsInnerNodeCurrency">
                    {this.updateToCurrency(
                      itemMap[rec] ? itemMap[rec].budgeted * 12 : 0,
                      false
                    )}
                  </p>
                </Segment>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2} className="savingsItemSubSectionRow">
              <Grid.Column className="savingsItemSubSection">
                <Segment>
                  <h5>How You Compare</h5>
                  <p className="savingsCurrency savingsInnerNodeCurrency">
                    {this.updateToCurrency(
                      itemMap[rec] ? itemMap[rec].spent - 50 : 0,
                      false
                    )}
                  </p>
                </Segment>
              </Grid.Column>
              <Grid.Column className="savingsItemSubSection">
                <Segment>
                  <h5>Suggested Monthly Additions</h5>
                  <p className="savingsCurrency savingsInnerNodeCurrency">
                    {this.updateToCurrency(
                      itemMap[rec] ? itemMap[rec].spent + 50 : 0,
                      false
                    )}
                  </p>
                </Segment>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="savingsItemBtnContainer">
              <Grid.Column>
                <Button
                  className="savingsItemBtn greenHoverBtn"
                  onClick={() =>
                    editSavingsItem({ title: rec, value: savingItems[rec] })
                  }
                >
                  Spend
                </Button>
                <Button
                  className="savingsItemBtn blueHoverBtn"
                  onClick={() =>
                    editSavingsItem({ title: rec, value: savingItems[rec] })
                  }
                >
                  <Button.Content>
                    <Icon name="edit" />
                  </Button.Content>
                </Button>
                <Button
                  className="savingsItemBtn redHoverBtn"
                  title={rec}
                  onClick={removeSavingsItem}
                >
                  <Button.Content>
                    <Icon name="delete" />
                  </Button.Content>
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      );
    });

    return (
      <div>
        {Object.entries(general.savingItems).length === 0 && (
          <div>
            <Button
              primary
              className="addNewSavingsItemBtn"
              onClick={() => addSavingsItem({})}
            >
              Add New Savings Item
            </Button>
          </div>
        )}
        {Object.entries(general.savingItems).length !== 0 && (
          <div>
            <Divider horizontal>Savings Overview</Divider>
            <Header as="h1" color="green" style={{ textAlign: "center" }}>
              {this.updateToCurrency(general.savingsTotal, false)}
              <Header.Subheader>Total Savings</Header.Subheader>
            </Header>
            <div style={{ width: "550px", height: "550px", margin: "auto" }}>
              <Pie
                data={pieChartData}
                options={pieChartOptions}
                height={150}
                width={150}
              />
            </div>
            <Divider horizontal>Savings Items</Divider>
            <Button
              primary
              className="addNewSavingsItemBtn"
              onClick={() => addSavingsItem({})}
            >
              Add New Savings Item
            </Button>
            <Grid textAlign="center">{content}</Grid>)
          </div>
        )}
      </div>
    );
  }
}

export default BudgetSavings;
