import React, { Component } from "react";
import { Modal, Header, Grid } from "semantic-ui-react";
import { connect } from "react-redux";
import { Bar, HorizontalBar, Doughnut } from "react-chartjs-2";
import USAMap from "react-usa-map";
import { SVGMap, USA } from "react-svg-map";
import "react-svg-map/lib/index.css";

import { closeModal } from "./modalActions";

const actions = { closeModal };

class BudgetCompareModal extends Component {
  state = {
    pointedLocation: null,
    tooltipStyle: {
      display: "none"
    }
  };

  mapHandler = event => {
    alert(event.target.dataset.name);
  };

  statesCustomConfig = () => {
    return {
      NJ: {
        fill: "navy",
        clickHandler: event =>
          console.log("Custom handler for NJ", event.target.dataset)
      },
      NY: {
        fill: "#CC0000"
      }
    };
  };

  handleLocationMouseOver = event => {
    const pointedLocation = event.target.id;
    this.setState({ pointedLocation });
  };

  handleLocationMouseOut = () => {
    this.setState({ pointedLocation: null, tooltipStyle: { display: "none" } });
  };

  handlelocationMouseMove = event => {
    const tooltipStyle = {
      display: 'block',
      top: event.clientY + 10,
      left: event.clientX - 100,
      zIndex: 100
    };
    this.setState({ tooltipStyle });
  }

  render() {
    const { title, budgeted } = this.props;

    // DIRECT COMPARE CHARTS
    const directCompareData = {
      labels: ["Me", "Direct Compare"],
      datasets: [
        {
          label: title,
          backgroundColor: ["rgba(124,252,0,0.2)", "rgb(255,140,0,0.2)"],
          borderColor: ["rgba(124,252,0,1)", "rgba(255,140,0,1)"],
          borderWidth: 1,
          hoverBackgroundColor: ["rgba(124,252,0,0.4)", "rgba(255,140,0,0.4)"],
          hoverBorderColor: ["rgba(124,252,0,1)", "rgba(255,140,0,1)"],
          data: [
            budgeted,
            (
              Math.floor(Math.random() * (budgeted * 1.3)) +
              budgeted * 0.8
            ).toFixed(2)
          ]
        }
      ]
    };
    const directCompareOptions = {
      legend: {
        display: false
      },
      scales: {
        xAxes: [
          {
            ticks: {
              beginAtZero: true,
              callback: function(label, index, labels) {
                return "$" + label;
              }
            }
          }
        ]
      }
    };

    // AGE CHARTS
    const ageChartData = {
      labels: ["Me", "DC", "18-25", "26-35", "36-45", "46-55", "56+"],
      datasets: [
        {
          label: title,
          backgroundColor: [
            "rgba(124,252,0,0.2)",
            "rgb(255,140,0,0.2)",
            "rgba(65,105,225,0.2)",
            "rgba(65,105,225,0.2)",
            "rgba(65,105,225,0.2)",
            "rgba(65,105,225,0.2)",
            "rgba(65,105,225,0.2)"
          ],
          borderColor: [
            "rgba(124,252,0,1)",
            "rgba(255,140,0,1)",
            "rgba(65,105,225,1)",
            "rgba(65,105,225,1)",
            "rgba(65,105,225,1)",
            "rgba(65,105,225,1)",
            "rgba(65,105,225,1)"
          ],
          borderWidth: 1,
          hoverBackgroundColor: [
            "rgba(124,252,0,0.4)",
            "rgba(255,140,0,0.4)",
            "rgba(65,105,225,0.4)",
            "rgba(65,105,225,0.4)",
            "rgba(65,105,225,0.4)",
            "rgba(65,105,225,0.4)",
            "rgba(65,105,225,0.4)"
          ],
          hoverBorderColor: [
            "rgba(124,252,0,1)",
            "rgba(255,140,0,1)",
            "rgba(65,105,255,1)",
            "rgba(65,105,255,1)",
            "rgba(65,105,255,1)",
            "rgba(65,105,255,1)",
            "rgba(65,105,255,1)"
          ],
          data: [
            budgeted,
            (
              Math.floor(Math.random() * (budgeted * 1.7)) +
              budgeted * 0.6
            ).toFixed(2),
            (
              Math.floor(Math.random() * (budgeted * 1.7)) +
              budgeted * 0.6
            ).toFixed(2),
            (
              Math.floor(Math.random() * (budgeted * 1.7)) +
              budgeted * 0.6
            ).toFixed(2),
            (
              Math.floor(Math.random() * (budgeted * 1.7)) +
              budgeted * 0.6
            ).toFixed(2),
            (
              Math.floor(Math.random() * (budgeted * 1.7)) +
              budgeted * 0.6
            ).toFixed(2),
            (
              Math.floor(Math.random() * (budgeted * 1.7)) +
              budgeted * 0.6
            ).toFixed(2)
          ]
        }
      ]
    };

    // Sex Charts
    const sexChartData = {
      labels: ["Me", "Male", "Female"],
      datasets: [
        {
          backgroundColor: [
            "rgba(124,252,0,0.2)",
            "rgb(65,105,255,0.2)",
            "rgba(255,192,203,0.2)"
          ],
          borderColor: [
            "rgba(124,252,0,1)",
            "rgb(65,105,255,1)",
            "rgba(255,192,203,1)"
          ],
          borderWidth: 1,
          hoverBackgroundColor: [
            "rgba(124,252,0,0.4)",
            "rgb(65,105,255,0.4)",
            "rgba(255,192,203,0.4)"
          ],
          data: [
            budgeted,
            (
              Math.floor(Math.random() * (budgeted * 1.7)) +
              budgeted * 0.6
            ).toFixed(2),
            (
              Math.floor(Math.random() * (budgeted * 1.7)) +
              budgeted * 0.6
            ).toFixed(2)
          ]
        }
      ]
    };

    // Income Charts
    const incomeChartData = {
      labels: [
        "Me",
        "DC",
        "$0K-$9.7K",
        "$9.7K-$39K",
        "$40K-$84K",
        "$84K-160K",
        "$161K-$204K",
        "$205K-$510K",
        "$511K+"
      ],
      datasets: [
        {
          label: title,
          backgroundColor: [
            "rgba(124,252,0,0.2)",
            "rgb(255,140,0,0.2)",
            "rgba(65,105,225,0.2)",
            "rgba(65,105,225,0.2)",
            "rgba(65,105,225,0.2)",
            "rgba(65,105,225,0.2)",
            "rgba(65,105,225,0.2)",
            "rgba(65,105,225,0.2)",
            "rgba(65,105,225,0.2)"
          ],
          borderColor: [
            "rgba(124,252,0,1)",
            "rgba(255,140,0,1)",
            "rgba(65,105,225,1)",
            "rgba(65,105,225,1)",
            "rgba(65,105,225,1)",
            "rgba(65,105,225,1)",
            "rgba(65,105,225,1)",
            "rgba(65,105,225,1)",
            "rgba(65,105,225,1)"
          ],
          borderWidth: 1,
          hoverBackgroundColor: [
            "rgba(124,252,0,0.4)",
            "rgba(255,140,0,0.4)",
            "rgba(65,105,225,0.4)",
            "rgba(65,105,225,0.4)",
            "rgba(65,105,225,0.4)",
            "rgba(65,105,225,0.4)",
            "rgba(65,105,225,0.4)",
            "rgba(65,105,225,0.4)",
            "rgba(65,105,225,0.4)"
          ],
          hoverBorderColor: [
            "rgba(124,252,0,1)",
            "rgba(255,140,0,1)",
            "rgba(65,105,255,1)",
            "rgba(65,105,255,1)",
            "rgba(65,105,255,1)",
            "rgba(65,105,255,1)",
            "rgba(65,105,255,1)",
            "rgba(65,105,255,1)",
            "rgba(65,105,255,1)"
          ],
          data: [
            budgeted,
            (
              Math.floor(Math.random() * (budgeted * 1.7)) +
              budgeted * 0.6
            ).toFixed(2),
            (
              Math.floor(Math.random() * (budgeted * 1.7)) +
              budgeted * 0.6
            ).toFixed(2),
            (
              Math.floor(Math.random() * (budgeted * 1.7)) +
              budgeted * 0.6
            ).toFixed(2),
            (
              Math.floor(Math.random() * (budgeted * 1.7)) +
              budgeted * 0.6
            ).toFixed(2),
            (
              Math.floor(Math.random() * (budgeted * 1.7)) +
              budgeted * 0.6
            ).toFixed(2),
            (
              Math.floor(Math.random() * (budgeted * 1.7)) +
              budgeted * 0.6
            ).toFixed(2),
            (
              Math.floor(Math.random() * (budgeted * 1.7)) +
              budgeted * 0.6
            ).toFixed(2),
            (
              Math.floor(Math.random() * (budgeted * 1.7)) +
              budgeted * 0.6
            ).toFixed(2),
            (
              Math.floor(Math.random() * (budgeted * 1.7)) +
              budgeted * 0.6
            ).toFixed(2),
            (
              Math.floor(Math.random() * (budgeted * 1.7)) +
              budgeted * 0.6
            ).toFixed(2)
          ]
        }
      ]
    };

    // Occupation Charts
    const occupationChartData = {
      labels: [
        "Me",
        "DC",
        "Teacher",
        "Engineer",
        "Entrepreneur",
        "Construction",
        "Historian"
      ],
      datasets: [
        {
          label: title,
          backgroundColor: [
            "rgba(124,252,0,0.2)",
            "rgb(255,140,0,0.2)",
            "rgba(65,105,225,0.2)",
            "rgba(65,105,225,0.2)",
            "rgba(65,105,225,0.2)",
            "rgba(65,105,225,0.2)",
            "rgba(65,105,225,0.2)"
          ],
          borderColor: [
            "rgba(124,252,0,1)",
            "rgba(255,140,0,1)",
            "rgba(65,105,225,1)",
            "rgba(65,105,225,1)",
            "rgba(65,105,225,1)",
            "rgba(65,105,225,1)",
            "rgba(65,105,225,1)"
          ],
          borderWidth: 1,
          hoverBackgroundColor: [
            "rgba(124,252,0,0.4)",
            "rgba(255,140,0,0.4)",
            "rgba(65,105,225,0.4)",
            "rgba(65,105,225,0.4)",
            "rgba(65,105,225,0.4)",
            "rgba(65,105,225,0.4)",
            "rgba(65,105,225,0.4)"
          ],
          hoverBorderColor: [
            "rgba(124,252,0,1)",
            "rgba(255,140,0,1)",
            "rgba(65,105,255,1)",
            "rgba(65,105,255,1)",
            "rgba(65,105,255,1)",
            "rgba(65,105,255,1)",
            "rgba(65,105,255,1)"
          ],
          data: [
            budgeted,
            (
              Math.floor(Math.random() * (budgeted * 1.7)) +
              budgeted * 0.6
            ).toFixed(2),
            (
              Math.floor(Math.random() * (budgeted * 1.7)) +
              budgeted * 0.6
            ).toFixed(2),
            (
              Math.floor(Math.random() * (budgeted * 1.7)) +
              budgeted * 0.6
            ).toFixed(2),
            (
              Math.floor(Math.random() * (budgeted * 1.7)) +
              budgeted * 0.6
            ).toFixed(2),
            (
              Math.floor(Math.random() * (budgeted * 1.7)) +
              budgeted * 0.6
            ).toFixed(2),
            (
              Math.floor(Math.random() * (budgeted * 1.7)) +
              budgeted * 0.6
            ).toFixed(2),
            (
              Math.floor(Math.random() * (budgeted * 1.7)) +
              budgeted * 0.6
            ).toFixed(2)
          ]
        }
      ]
    };

    // Location Charts
    const locationChartOptions = {};

    const generalBarChartOptions = {
      legend: {
        display: false
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              callback: function(label, index, labels) {
                return "$" + label;
              }
            }
          }
        ]
      }
    };

    return (
      <Modal size="large" open={true} onClose={this.props.closeModal}>
        <Modal.Header>How You Compare: {title}</Modal.Header>
        <Modal.Content>
          <Header as="h3">Direct Compare</Header>
          <HorizontalBar
            data={directCompareData}
            options={directCompareOptions}
            height={50}
          />
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column>
                <Header as="h3">Age</Header>
                <Bar
                  data={ageChartData}
                  options={generalBarChartOptions}
                  width={150}
                  height={75}
                />
              </Grid.Column>
              <Grid.Column>
                <Header as="h3">Sex</Header>
                <Doughnut data={sexChartData} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Header as="h3">Income</Header>
                <Bar
                  data={incomeChartData}
                  options={generalBarChartOptions}
                  width={150}
                  height={82}
                />
              </Grid.Column>
              <Grid.Column>
                <Header as="h3">Occupation</Header>
                <Bar
                  data={occupationChartData}
                  options={generalBarChartOptions}
                  width={150}
                  height={75}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Header as="h3">Location</Header>
          <SVGMap
            map={USA}
            onLocationMouseOver={this.handleLocationMouseOver}
            onLocationMouseOut={this.handleLocationMouseOut}
            onLoactionMouseMove={this.handlelocationMouseMove}
          />
          <div
            className="examples__block__map__tooltip"
            style={this.state.tooltipStyle}
          >
            {this.state.pointedLocation}
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

export default connect(
  null,
  actions
)(BudgetCompareModal);
