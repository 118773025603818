export const RandomHexColorArray = (size) => {
    let hexArr = [];
    while (size > 0) {
        hexArr.push('#'+(Math.random()*0xFFFFFFF<<0).toString(16));
        size--;
    }
    return hexArr;
}

export const RandomRGBColorArray = (size) => {
    let rgbArr = [];
    while (size > 0) {
        let r = Math.floor(Math.random() * 255);
        let g = Math.floor(Math.random() * 255);
        let b = Math.floor(Math.random() * 255);

        rgbArr.push("rgb(" + r + "," + g + "," + b + ")");

        size--;
    }
    return rgbArr;
}