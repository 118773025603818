import React from "react";
import { connect } from "react-redux";
import { Icon, Button } from "semantic-ui-react";
import { openModal } from "../modals/modalActions";
import { Grid, Image } from "semantic-ui-react";
import { Provider, Heading, Subhead } from "rebass";
import {
  Hero,
  CallToAction,
  ScrollDownIndicator,
  Laptop,
  Phone,
  Flex,
  Contributor
} from "react-landing-page";
import Testimony from "react-landing-page/dist/components/Testimony";

const actions = {
  openModal
};

const HomePage = ({ history, openModal }) => {
  return (
    <Provider>
      <Hero color="black" backgroundImage="/assets/cool-background.png">
        <Grid columns={2}>
          <Grid.Row style={{ height: "500px" }}>
            <Grid.Column>
              <span>
                <Laptop mt={3} color="white" src="/assets/webLandingPage.PNG" />
              </span>
              <span>
                <Phone
                  color="black"
                  notch
                  width={225}
                  style={{ transform: "translate(685px, -530px)" }}
                  src="/assets/phonePlaceholder.jpeg"
                />
              </span>
              <CallToAction
                bg="black"
                mb={2}
                style={{ transform: "translate(145px, -450px" }}
              >
                Download on iOS App Store
              </CallToAction>
              <CallToAction
                bg="black"
                mb={2}
                style={{ transform: "translate(155px, -450px" }}
              >
                Google Play Store Coming Soon
              </CallToAction>
            </Grid.Column>
            <Grid.Column>
              <Heading>
                <div>
                  <Icon
                    size="huge"
                    name="angle double up"
                    style={{ float: "left" }}
                  />
                  <div className="content landingPageLogo">Atlas</div>
                </div>
              </Heading>
              <div className="landingPageVersion">
                <span>VERSION (0.3.06 ALPHA) </span>
                <Button
                  className="roadmapBtn"
                  onClick={() => openModal("RoadmapModal")}
                >
                  ROADMAP
                </Button>
              </div>
              <div className="landingPageAccountAccessButtons">
                <div
                  onClick={() => openModal("LoginModal")}
                  className="ui huge black inverted button"
                >
                  Login
                  <i className="right arrow icon" />
                </div>
                <div
                  onClick={() => openModal("RegisterModal")}
                  className="ui huge black inverted button"
                  style={{ float: "left" }}
                >
                  Sign Up
                  <i className="right arrow icon" />
                </div>
              </div>
              <Subhead className="landingPageSubHeading">
                Plot Your Financial Freedom Today.
              </Subhead>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row />
        </Grid>
        <ScrollDownIndicator />
      </Hero>
      <Grid
        textAlign="center"
        verticalAlign="middle"
        className="landingPageContentGrid"
      >
        <Grid.Row columns={2} className="landingPageContentGridRow">
          <Grid.Column>
            <h1>Easy, Effective, And Free Budgeting</h1>
            <p>Placeholder</p>
          </Grid.Column>
          <Grid.Column>
            <Image src="/assets/accountInfoBanner.png" />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2} className="landingPageContentGridRow">
          <Grid.Column>
            <Image src="/assets/accountInfoBanner.png" />
          </Grid.Column>
          <Grid.Column>
            <h1>Track All of Your Personal Finances In One Place</h1>
            <p>Placeholder</p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2} className="landingPageContentGridRow">
          <Grid.Column>
            <h1>Compare Yourself Directly Against Others Like You</h1>
            <p>Placeholder</p>
          </Grid.Column>
          <Grid.Column>
            <Image src="/assets/accountInfoBanner.png" />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2} className="landingPageContentGridRow">
          <Grid.Column>
            <Image src="/assets/accountInfoBanner.png" />
          </Grid.Column>
          <Grid.Column>
            <h1>An In-Depth Goal And Scoring System</h1>
            <p>Placeholder</p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Heading textAlign="center" style={{marginTop: "50px"}}>The Atlas Team</Heading>
      <Flex justifyContent="space-around">
        <Contributor
          fullName="Frank Garcia"
          title="Software Developer"
          avatar="/assets/FrankTeamPic.jpg"
        >
          <Flex>
            <a
              href="https://www.linkedin.com/in/frank-garcia-35b456106/"
              style={{ marginRight: "10px" }}
            >
              LinkedIn
            </a>
            <a
              href="https://github.com/Frank-The-Tank"
              style={{ marginRight: "10px" }}
            >
              GitHub
            </a>
          </Flex>
        </Contributor>
        <Contributor
          fullName="Jacques Joubert"
          title="Software Developer"
          avatar="http://i.pravatar.cc/230"
        >
          <Flex>
            <a
              href="https://www.linkedin.com/in/jacques-joubert-043146161/"
              style={{ marginRight: "10px" }}
            >
              LinkedIn
            </a>
            <a
              href="https://github.com/frederickjjoubert"
              style={{ marginRight: "10px" }}
            >
              GitHub
            </a>
          </Flex>
        </Contributor>
      </Flex>
    </Provider>
  );
};

export default connect(
  null,
  actions
)(HomePage);
