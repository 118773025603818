import React, { Component } from "react";
import { Modal, Menu, Header, Button, Icon } from "semantic-ui-react";
import { connect } from "react-redux";

import { closeModal } from "./modalActions";
import {
  addIncomeSource,
  removeIncomeSource,
  updateIncomeSourceData
} from "../budget/budgetActions";
import ClickToEdit from "../../app/common/util/ClickToEdit/ClickToEdit";

const actions = {
  closeModal,
  addIncomeSource,
  removeIncomeSource,
  updateIncomeSourceData
};

class IncomeModal extends Component {
  addNewIncomeSource = () => {
    this.props.addIncomeSource(this.props.authUID);
  };

  deleteIncomeSource = sourceId => {
    this.props.removeIncomeSource(sourceId, this.props.authUID);
  };

  updateSourceData = (newData, fieldToUpdate) => {
    this.props.updateIncomeSourceData(
      parseInt(newData),
      fieldToUpdate.type,
      fieldToUpdate.sourceId,
      this.props.authUID
    );
  };

  calculateSourceNetValue = () => {
    console.log("Calcuate source net value");
  };

  createIncomeSourceView = incomeSources => {
    let rows = [];
    incomeSources.forEach(source => {
      rows.push(
        <Menu horizontal="true" secondary widths="4">
          <Menu.Item>{source.source}</Menu.Item>
          <Menu.Item>
            <span>$</span>
            <ClickToEdit
              fieldAffected={{ sourceId: source.id, type: "gross" }}
              value={source.gross}
              endEditing={this.updateSourceData}
            />
          </Menu.Item>
          <Menu.Item>
            <span>$</span>
            <ClickToEdit
              fieldAffected={{ sourceId: source.id, type: "net" }}
              value={source.net}
              endEditing={this.updateSourceData}
            />
          </Menu.Item>
          <Menu.Item>
            <Button color="blue" onClick={this.calculateSourceNetValue}>
              Calculate Net
            </Button>
            <Button
              color="red"
              style={{ width: "51px" }}
              onClick={() => this.deleteIncomeSource(source.id)}
            >
              <Icon name="remove" />
            </Button>
          </Menu.Item>
        </Menu>
      );
    });
    return rows;
  };

  render() {
    const { gross, net, incomeSources } = this.props;
    return (
      <Modal size="large" open={true} onClose={this.props.closeModal}>
        <Modal.Header>Edit Income</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Menu horizontal="true" style={{ width: "308px" }}>
              <Menu.Item>
                <Header as="h4">
                  Total Gross Income
                  <Header.Subheader style={{ color: "green" }}>
                    ${gross}
                  </Header.Subheader>
                </Header>
              </Menu.Item>
              <Menu.Item>
                <Header as="h4">
                  Total Net Income
                  <Header.Subheader style={{ color: "green" }}>
                    ${net}
                  </Header.Subheader>
                </Header>
              </Menu.Item>
            </Menu>
            <Menu.Header
              as="h4"
              style={{ marginLeft: "10px" }}
              className="editIncomeDataHeader"
            >
              Income Sources
            </Menu.Header>
            <Button
              icon
              compact
              fluid
              color="green"
              className="editIncomeAddBtn"
              onClick={this.addNewIncomeSource}
            >
              <Icon name="plus" />
            </Button>
            <Menu horizontal="true" secondary widths="4">
              <Menu.Item>
                <b>Source</b>
              </Menu.Item>
              <Menu.Item>
                <b>Gross Income</b>
              </Menu.Item>
              <Menu.Item>
                <b>Net Income</b>
              </Menu.Item>
              <Menu.Item />
            </Menu>
            {this.createIncomeSourceView(incomeSources)}
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

export default connect(
  null,
  actions
)(IncomeModal);
