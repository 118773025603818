import React, { Component } from "react";
import {
  Modal,
  Button,
  Menu,
  Grid,
  Search,
  Form,
  Divider,
  Header,
  Icon,
  Segment,
  Label
} from "semantic-ui-react";
import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { closeModal } from "./modalActions";
import { addBudgetRecord } from "../budget/budgetActions";
import { createNumberMask } from "redux-form-input-masks";
import budgetAddItems from "../../app/common/BudgetAddModalItems";
import TextInput from "../../app/common/form/TextInput";

const actions = { closeModal, addBudgetRecord };

const currencyMask = createNumberMask({
  prefix: "$",
  decimalPlaces: 2,
  locale: "en-US"
});

class BudgetAddModal extends Component {
  state = {
    activeItem: this.props.position,
    clickedItem: "NONE",
    iconIden: "ban",
    variable: false,
    preTax: false,
    savings: false,
    debt: false,
    budgeted: 0.0
  };

  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  handleBudgetItemClick = (e, { title, iconiden, defaults }) => {
    let variableVal = false;
    let preTaxVal = false;
    let savingsVal = false;
    let debtVal = false;

    defaults.forEach(type => {
      switch(type) {
        case "variable":
          variableVal = true;
          break;
        case "preTax":
          preTaxVal = true;
          break;
        case "savings":
          savingsVal = true;
          break;
        case "debt":
          debtVal = true;
          break;
        default:
          break;
      }
    });

    this.setState(
      {
        clickedItem: title,
        iconIden: iconiden ,
        variable: variableVal,
        preTax: preTaxVal,
        savings: savingsVal,
        debt: debtVal
      }
    );
  };

  updateBudgetedVal = evt => {
    this.setState({
      budgeted: Number(evt.target.value.replace(/[^0-9.-]+/g, "")) * 10
    });
  };

  updateVariableVal = evt => {
    this.setState(prevState => ({ variable: !prevState.variable }));
  };
  updatePreTaxVal = evt => {
    this.setState(prevState => ({ preTax: !prevState.preTax }));
  };
  udpateSavingsVal = evt => {
    this.setState(prevState => ({ savings: !prevState.savings }));
  };
  updateDebtVal = evt => {
    this.setState(prevState => ({ debt: !prevState.debt }));
  };

  createBudgetRecord = () => {
    this.props.closeModal();
    this.props.addBudgetRecord(
      this.state.activeItem,
      this.state.clickedItem,
      this.state.iconIden,
      this.state.budgeted,
      this.state.variable,
      this.state.preTax,
      this.state.savings,
      this.state.debt,
      this.props.authUID
    );
  };

  render() {
    const { unallocatedBudget } = this.props;
    const { activeItem, clickedItem, iconIden } = this.state;
    const budgetItems = budgetAddItems;
    return (
      <Modal size="large" open={true} onClose={this.props.closeModal}>
        <Modal.Header>Choose an item to add</Modal.Header>
        <Modal.Content>
          <Grid>
            <Grid.Column width={4}>
              <Menu fluid vertical tabular>
                <Menu.Item
                  name="housing"
                  active={activeItem === "housing"}
                  onClick={this.handleItemClick}
                />
                <Menu.Item
                  name="generalLiving"
                  active={activeItem === "generalLiving"}
                  onClick={this.handleItemClick}
                />
                <Menu.Item
                  name="investments"
                  active={activeItem === "investments"}
                  onClick={this.handleItemClick}
                />
                <Menu.Item
                  name="indulgences"
                  active={activeItem === "indulgences"}
                  onClick={this.handleItemClick}
                />
              </Menu>
            </Grid.Column>
            <Grid.Column stretched width={12}>
              <Search />
              <Grid.Row className="budgetAddModalItemHolder">
                {budgetItems[activeItem].map((item, i) => {
                  return (
                    <Grid.Column
                      key={i}
                      width={3}
                      className="budgetAddModalItem"
                    >
                      <Button
                        basic
                        className="budgetAddModalItemBtn"
                        title={item.title}
                        iconiden={item.icon}
                        defaults={item.default}
                        onClick={this.handleBudgetItemClick}
                      >
                        <FontAwesomeIcon
                          icon={item.icon}
                          className="budgetAddModalIcon"
                        />
                        <p>{item.title}</p>
                      </Button>
                    </Grid.Column>
                  );
                })}
              </Grid.Row>
            </Grid.Column>
            <Divider horizontal style={{ width: "100%", height: "10%" }}>
              <Header as="h4">
                <Icon name="edit" />
                Item Specifications
              </Header>
            </Divider>
            <Grid.Column width={16} className="budgetAddModalFooterItemHolder">
              <Grid.Row>
                <Grid.Column width={8} className="budgetAddModalFooterItem">
                  <h4 style={{ margin: 0 }}>Item</h4>
                  <div style={{ textAlign: "center" }}>
                    <FontAwesomeIcon icon={iconIden} size="3x" />
                    <p>{clickedItem}</p>
                  </div>
                </Grid.Column>
                <Grid.Column width={8} className="budgetAddModalFooterItem">
                  <h4 style={{ float: "left", marginRight: "10px" }}>
                    Budget Left:{" "}
                  </h4>
                  <p style={{ width: "65px", float: "left" }}>
                    ${unallocatedBudget.toFixed(2)}
                  </p>
                  <h4
                    style={{ float: "left", marginRight: "10px", marginTop: 0 }}
                  >
                    Suggested:{" "}
                  </h4>
                  <p>$100</p>
                  <h4
                    style={{
                      float: "left",
                      marginTop: "8px",
                      marginRight: "2px"
                    }}
                  >
                    Budgeted:{" "}
                  </h4>
                  <Form style={{ float: "left", width: "250px" }}>
                    <Field
                      name="budgeted"
                      type="text"
                      component={TextInput}
                      {...currencyMask}
                      value={this.state.budgeted}
                      onChange={evt => this.updateBudgetedVal(evt)}
                    />
                  </Form>
                </Grid.Column>
              </Grid.Row>
            </Grid.Column>
            <Grid columns={2} className="budgetAddModalFooterItem2">
              <Grid.Row style={{ paddingTop: 0, paddingBottom: 0 }}>
                <Grid.Column>
                  <Segment padded onClick={this.updateVariableVal}>
                    <Label
                      attached="top"
                      color={this.state.variable ? "blue" : "grey"}
                    >
                      Variable Item
                    </Label>
                    <p>
                      Variable items spent amount is subject to change every
                      month. Choose this item type if the amount you will be
                      spending on this item is going to be chaning on a month to
                      month basis.
                    </p>
                  </Segment>
                </Grid.Column>
                <Grid.Column>
                  <Segment padded onClick={this.udpateSavingsVal}>
                    <Label
                      attached="top"
                      color={this.state.savings ? "blue" : "grey"}
                    >
                      Savings Item
                    </Label>
                    <p>
                      Savings items keep track of how much you have saved and
                      add your monthly allocation to the total every month.
                      Choose this item if you are allocated money to this item
                      with the goal of saving later down the line.
                    </p>
                  </Segment>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Segment padded onClick={this.updateDebtVal}>
                    <Label
                      attached="top"
                      color={this.state.debt ? "blue" : "grey"}
                    >
                      Debt Item
                    </Label>
                    <p>
                      Debt items keep track of how much debt you have left and
                      pay off the total based on your monthly allocation. Choose
                      this type if you are allocating money to this item to pay
                      off outstanding debts.
                    </p>
                  </Segment>
                </Grid.Column>
                <Grid.Column>
                  <Segment padded onClick={this.updatePreTaxVal}>
                    <Label
                      attached="top"
                      color={this.state.preTax ? "blue" : "grey"}
                    >
                      PreTax Item
                    </Label>
                    <p>
                      Pre-Tax items are regular budget items but instead of the
                      amount budgeted being taken out of net income, it is taken
                      out of gross. Choose this type if your item is taken out
                      of your gross income.
                    </p>
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid.Column width={16}>
              <Grid.Row>
                <Grid.Column
                  width={4}
                  className="budgetAddModalFooterItem budgetAddModalAddBtn"
                >
                  <Button
                    className="budgetAddItemModalBtn budgetAddItemBtn"
                    onClick={this.createBudgetRecord}
                  >
                    Add
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid.Column>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}

export default connect(
  null,
  actions
)(reduxForm({ form: "addBudgetItemForm" })(BudgetAddModal));
