import React, { Component } from "react";
import { Form, Header, Grid, Image, Button } from "semantic-ui-react";
import { reduxForm } from "redux-form";
import { combineValidators, isRequired } from "revalidate";
import { Field } from "redux-form";
import { createNumberMask } from "redux-form-input-masks";
import TextInput from "../../../app/common/form/TextInput";
import SelectInput from "../../../app/common/form/SelectInput";
import DateInput from "../../../app/common/form/DateInput";

const currenyMask = createNumberMask({
  prefix: "$",
  decimalPlaces: 2,
  locale: "en-US"
});

const sex = [
  { key: "male", text: "Male", value: "male" },
  { key: "female", text: "Female", value: "female" }
];

const states = [
  { key: "alabama", text: "Alabama", value: "Alabama" },
  { key: "alaska", text: "Alaska", value: "Alaska" },
  { key: "arizona", text: "Arizona", value: "Arizona" },
  { key: "arkansas", text: "Arkansas", value: "Arkansas" },
  { key: "california", text: "California", value: "California" },
  { key: "colorado", text: "Colorado", value: "Colorado" },
  { key: "connecticut", text: "Connecticut", value: "Connecticut" },
  { key: "delaware", text: "Delaware", value: "Delaware" },
  { key: "florida", text: "Florida", value: "Florida" },
  { key: "georgia", text: "Georgia", value: "Georgia" },
  { key: "hawaii", text: "Hawaii", value: "Hawaii" },
  { key: "idaho", text: "Idaho", value: "Idaho" },
  { key: "illinois", text: "Illinois", value: "Illinois" },
  { key: "indiana", text: "Indiana", value: "Indiana" },
  { key: "iowa", text: "Iowa", value: "Iowa" },
  { key: "kansas", text: "Kansas", value: "Kansas" },
  { key: "kentucky", text: "Kentucky", value: "Kentucky" },
  { key: "louisiana", text: "Louisiana", value: "Louisiana" },
  { key: "maine", text: "Maine", value: "Maine" },
  { key: "maryland", text: "Maryland", value: "Maryland" },
  { key: "massachusetts", text: "Massachusetts", value: "Massachusetts" },
  { key: "michigan", text: "Michigan", value: "Michigan" },
  { key: "minnesota", text: "Minnesota", value: "Minnesota" },
  { key: "mississippi", text: "Mississippi", value: "Mississippi" },
  { key: "missouri", text: "Missouri", value: "Missouri" },
  { key: "montana", text: "Montana", value: "Montana" },
  { key: "nebraska", text: "Nebraska", value: "Nebraska" },
  { key: "nevada", text: "Nevada", value: "Nevada" },
  { key: "new hampshire", text: "New Hampshire", value: "New Hampshire" },
  { key: "new jersey", text: "New Jersey", value: "New Jersey" },
  { key: "new mexico", text: "New Mexico", value: "New Mexico" },
  { key: "new york", text: "New York", value: "New York" },
  { key: "north carolina", text: "North Carolina", value: "North Carolina" },
  { key: "north dakota", text: "North Dakota", value: "North Dakota" },
  { key: "ohio", text: "Ohio", value: "Ohio" },
  { key: "oklahoma", text: "Oklahoma", value: "Oklahoma" },
  { key: "oregon", text: "Oregon", value: "Oregon" },
  { key: "pennsylvania", text: "Pennsylvania", value: "Pennsylvania" },
  { key: "rhode island", text: "Rhode Island", value: "Rhode Island" },
  { key: "south carolina", text: "South Carolina", value: "South Carolina" },
  { key: "south dakota", text: "South Dakota", value: "South Dakota" },
  { key: "tennessee", text: "Tennessee", value: "Tennessee" },
  { key: "texas", text: "Texas", value: "Texas" },
  { key: "utah", text: "Utah", value: "Utah" },
  { key: "vermont", text: "Vermont", value: "Vermont" },
  { key: "virginia", text: "Virginia", value: "Virginia" },
  { key: "washington", text: "Washington", value: "Washington" },
  { key: "west virginia", text: "West Virginia", value: "West Virginia" },
  { key: "wisconsin", text: "Wisconsin", value: "Wisconsin" },
  { key: "wyoming", text: "Wyoming", value: "Wyoming" },
];

const occupations = [{ key: "beingYou", text: "Being You", value: "beingYou" }];

const validate = combineValidators({
  fullname: isRequired({ message: "Fullname is required " }),
  sex: isRequired({ message: "Sex is required" }),
  date: isRequired({ message: "Date of Birth is required" }),
  city: isRequired({ message: "City is required" }),
  occupation: isRequired({ message: "Occupation is required" }),
  employer: isRequired({ message: "Employer is required" }),
  experience: isRequired({ message: "Years of experience is required" })
});

export class StepPersonalInfo extends Component {
  render() {
    const { loadNextStep, invalid, submitting, pristine } = this.props;
    return (
      <Grid className="registerFormContent">
        <Grid.Column width={16} className="registerFormTopContent">
          <Image
            src="/assets/accountInfoBanner.png"
            fluid
            className="registerFormImage"
          />
          <p className="registerSectionDescriptionText">
            Tell us more about yourself. This data is used to help us compare
            you against others of similar stats and provide you with the best
            ways to conquer your financial future.
          </p>
          <Form
            error
            style={{ padding: 21 }}
            onSubmit={this.props.handleSubmit(loadNextStep)}
          >
            <Header as="h3" content="Fullname" floated="left" />
            <Field
              name="fullname"
              type="text"
              component={TextInput}
              placeholder="name please"
            />
            <Header as="h3" content="Sex" floated="left" />
            <Field
              name="sex"
              type="text"
              component={SelectInput}
              options={sex}
              placeholder="male or female, the math for transgender is too hard"
            />
            <Header as="h3" content="Date of Birth" floated="left" />
            <Field
              name="date"
              type="text"
              component={DateInput}
              dateFormat="YYYY/MM/DD"
              placeholder="when were you birthed"
            />
            <Header as="h3" content="State of Residence" floated="left" />
            <Field
              name="location"
              type="text"
              component={SelectInput}
              options={states} 
              placeholder=""
            />
            <Header as="h3" content="Occupation" floated="left" />
            <Field
              name="occupation"
              type="text"
              component={SelectInput}
              options={occupations}
              placeholder="what are you up to these days?"
            />
            <Header as="h3" content="Employer" floated="left" />
            <Field
              name="employer"
              type="text"
              component={TextInput}
              placeholder="who is giving you the money?"
            />
            <Header as="h3" content="Years of Experience" floated="left" />
            <Field
              name="experience"
              type="text"
              component={TextInput}
              placeholder="how many years have you been working in your field?"
            />
              <Header as="h3" content="Gross Yearly Income" floated="left" />
            <Field
              name="grossIncome"
              type="text"
              component={TextInput}
              {...currenyMask}
            />
            <Header as="h3" content="Net Yearly Income" floated="left" />
            <Button
              className="netIncomeCalculatorBtn"
              color="blue"
              onClick={this.calculateNetIncome}
            >
              Calculate My Net Income (WORK IN PROGRESS)
            </Button>
            <Field
              name="netIncome"
              type="text"
              component={TextInput}
              {...currenyMask}
            />
            <Button
              type="submit"
              className="registerFormBtn"
              disabled={invalid || submitting || pristine}
            >
              Next
            </Button>
          </Form>
        </Grid.Column>
      </Grid>
    );
  }
}

export default reduxForm({ form: "accountInfoForm", validate })(
  StepPersonalInfo
);
