import React, { Component } from "react";
import { Accordion } from "semantic-ui-react";
import ProfileListItem from "./ProfileListItem";
import ProfileListItemContent from "./ProfileListItemContent";

class ProfileList extends Component {
  state = { activeIndex: -1 };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  render() {
    const { activeIndex } = this.state;

    return (
      <Accordion>
        <Accordion.Title
          active={activeIndex === 0}
          index={0}
          onClick={this.handleClick}
        >
          <ProfileListItem />
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 0}>
          <ProfileListItemContent />
        </Accordion.Content>

        <Accordion.Title
          active={activeIndex === 1}
          index={1}
          onClick={this.handleClick}
        >
          <ProfileListItem />
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 1}>
          <ProfileListItemContent />
        </Accordion.Content>
      </Accordion>
    );
  }
}

export default ProfileList;
