import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { compose } from "redux";
import { Grid, Divider } from "semantic-ui-react";
import ProfileHeader from "../ProfileHeader/ProfileHeader";
import ProfileNav from "../ProfileNav/ProfileNav";
import ProfileFooter from "../ProfileFooter/ProfileFooter";
import contentHash from "../../../app/common/MockData/MockData";
import LoadingComponent from "../../../app/layout/LoadingComponent";

const mapState = ({ firebase: { auth }, firestore: { ordered, data } }) => ({
  users: data.users,
  authUID: auth.uid
});

const actions = {};

class ProfileDashboard extends Component {
  render() {
    const { users, authUID } = this.props;
    if (!isLoaded(users) || !isLoaded(authUID))
      return <LoadingComponent inverted={true} />;
    const profile = users[authUID].profile;
    return (
      <Grid>
        <Grid.Column width={16}>
          <ProfileHeader
            headerContent={contentHash.headerContent}
            overview={profile.overview}
            goals={profile.goals}
            archive={profile.archive}
          />
        </Grid.Column>
      </Grid>
    );
  }
}

export default compose(
  connect(
    mapState,
    actions
  ),
  firestoreConnect(props => {
    const authId = props.authUID == null ? "placeholder" : props.authUID;
    return [
      {
        collection: "users",
        doc: authId,
        subcollections: [{ collection: "profile" }]
      },
      {
        collection: "users",
        doc: authId,
        subcollections: [{ collection: "budget" }]
      },
    ];
  })
)(ProfileDashboard);
