import firebase from "firebase";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyD3Q3Dl-zkW26I2BGGjk2HyX7s-BJFQB_s",
  authDomain: "atlas-220300.firebaseapp.com",
  databaseURL: "https://atlas-220300.firebaseio.com",
  projectId: "atlas-220300",
  storageBucket: "",
  messagingSenderId: "510203776417"
};

firebase.initializeApp(firebaseConfig);
const firestore = firebase.firestore();
const settings = {
  timestampsInSnapshots: true
}
firestore.settings(settings);

export default firebase;
