import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { compose } from "redux";
import { Grid } from "semantic-ui-react";
import BudgetHeader from "../BudgetHeader/BudgetHeader";
import BudgetList from "../BudgetList/BudgetList";
import BudgetNav from "../BudgetNav/BudgetNav";
import { openModal } from "../../modals/modalActions";
import {
  updateBudgetData,
  compareChooser,
  removeSavingsItem,
  removeDebtItem
} from "../budgetActions";
import LoadingComponent from "../../../app/layout/LoadingComponent";

const mapState = ({ firebase: { auth }, firestore: { ordered, data } }) => ({
  users: data.users,
  authUID: auth.uid
});

const actions = {
  updateBudgetData,
  compareChooser,
  openModal,
  removeSavingsItem,
  removeDebtItem
};

class BudgetDashboard extends Component {
  updateBudgetData = (newData, fieldToUpdate) => {
    // check for + in string and solve if found
    newData = newData
      .split("+")
      .reduce((sum, val) => {
        return sum + parseFloat(val);
      }, 0)
      .toString();
    this.props.updateBudgetData(newData, fieldToUpdate, this.props.authUID);
  };

  onCompareChange = (event, data) => {
    if (data.checked) {
      this.props.compareChooser("net", this.props.authUID);
    } else {
      this.props.compareChooser("gross", this.props.authUID);
    }
  };

  displayRemoveModal = fieldData => {
    fieldData.authUID = this.props.authUID;
    this.props.openModal("BudgetDeleteModal", fieldData);
  };

  displayAddModal = fieldData => {
    fieldData.authUID = this.props.authUID;
    this.props.openModal("BudgetAddModal", fieldData);
  };

  displayCompareModal = fieldData => {
    fieldData.authUID = this.props.authUID;
    this.props.openModal("BudgetCompareModal", fieldData);
  };

  displayIdeaModal = fieldData => {
    fieldData.authUID = this.props.authUID;
    this.props.openModal("BudgetIdeaModal", fieldData);
  };

  displayIncomeModal = fieldData => {
    fieldData.authUID = this.props.authUID;
    this.props.openModal("IncomeModal", fieldData);
  };

  removeSavingsItem = (params, { title }) => {
    this.props.removeSavingsItem(title, this.props.authUID);
  };

  addSavingsItem = fieldData => {
    fieldData.authUID = this.props.authUID;
    this.props.openModal("AddSavingsItemModal", fieldData);
  };

  editSavingsItem = fieldData => {
    fieldData.authUID = this.props.authUID;
    this.props.openModal("EditSavingsItemModal", fieldData);
  };

  removeDebtItem = (params, { title }) => {
    this.props.removeDebtItem(title, this.props.authUID);
  };

  addDebtItem = fieldData => {
    fieldData.authUID = this.props.authUID;
    this.props.openModal("AddDebtItemModal", fieldData);
  };

  editDebtItem = fieldData => {
    fieldData.authUID = this.props.authUID;
    this.props.openModal("EditDebtItemModal", fieldData);
  };

  render() {
    const { users, authUID } = this.props;
    if (!isLoaded(users) || !isLoaded(authUID))
      return <LoadingComponent inverted={true} />;
    const user = users[authUID].budget;
    return (
      <Grid>
        <Grid.Column width={16}>
          <BudgetNav />
          <BudgetHeader
            general={user.general}
            onCompareChange={this.onCompareChange}
            openIncomeModal={this.displayIncomeModal}
          />
          <BudgetList
            className="standardSeparation"
            data={user.data}
            general={user.general}
            unallocatedBudget={user.general.unallocatedBudget}
            unallocatedSpending={user.general.unallocatedSpent}
            updateData={this.updateBudgetData}
            deleteData={this.displayRemoveModal}
            addData={this.displayAddModal}
            openCompareModal={this.displayCompareModal}
            openIdeaModal={this.displayIdeaModal}
            removeSavingsItem={this.removeSavingsItem}
            editSavingsItem={this.editSavingsItem}
            addSavingsItem={this.addSavingsItem}
            removeDebtItem={this.removeDebtItem}
            editDebtItem={this.editDebtItem}
            addDebtItem={this.addDebtItem}
          />
        </Grid.Column>
      </Grid>
    );
  }
}

export default compose(
  connect(
    mapState,
    actions
  ),
  firestoreConnect(props => {
    const authId = props.authUID == null ? "placeholder" : props.authUID;
    return [
      {
        collection: "users",
        doc: authId,
        subcollections: [{ collection: "budget" }]
      },
      {
        collection: "users",
        doc: authId,
        subcollections: [{ collection: "profile" }]
      }
    ];
  })
)(BudgetDashboard);
