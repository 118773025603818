import React, { Component } from "react";
import {
  Accordion,
  Icon,
  List,
  Menu,
  Grid,
  Button,
  Popup,
  Progress
} from "semantic-ui-react";
import ClickToEdit from "../../../app/common/util/ClickToEdit/ClickToEdit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class BudgetListContent extends Component {
  render() {
    const {
      data,
      updateData,
      deleteData,
      addData,
      unallocatedBudget,
      unallocatedSpending,
      openCompareModal,
      openIdeaModal
    } = this.props;

    const panels =
      data &&
      data.content.map(rec => ({
        key: rec.id,
        title: (
          <Accordion.Title
            className="secondaryBkgTheme rounded"
            style={{ marginBottom: 5 }}
          >
            <Icon name="dropdown" />
            <Icon name={rec.icon} style={{ marginRight: 10 }} />
            <span>
              {rec.title === "General Living" ? "Necessities" : rec.title}
            </span>
            <span style={{ position: "absolute", left: 218 }}>
              <span>${parseFloat(rec.budgeted).toFixed(2)}</span>
            </span>
            <span style={{ position: "absolute", left: 368 }}>
              ${parseFloat(rec.spent).toFixed(2)}
            </span>
            <span style={{ position: "absolute", left: 518 }}>
              ${parseFloat(rec.leftover).toFixed(2)}
            </span>
            <span style={{ position: "absolute", left: 668 }}>
              <Progress
                className="budgetAtlasScoreProgressHeader"
                value={rec.atlasScore}
                progress="value"
                size="small"
                inverted
                indicating
              />
            </span>
            <span style={{ float: "right", paddingRight: 10 }}>
              {parseFloat(rec.budgetedPercent).toFixed(1)}%
            </span>
          </Accordion.Title>
        ),
        content:
          rec.sections && rec.sections.length === 0 ? (
            <List.Item style={{ borderTop: "none" }}>
              <List.Content style={{ textAlign: "left" }}>
                <Button
                  icon
                  compact
                  fluid
                  className="budgetAddBtn"
                  onClick={() =>
                    addData({
                      position: rec.type,
                      unallocatedBudget: unallocatedBudget
                    })
                  }
                >
                  <Icon name="plus" className="secondaryTheme" />
                </Button>
              </List.Content>
            </List.Item>
          ) : (
            rec.sections.map((subRec, i) => {
              return (
                <List
                  key={subRec.id}
                  divided
                  relaxed
                  style={{ marginLeft: 10 }}
                >
                  <List.Item style={{ display: "flex", alignItems: "center" }}>
                    <List.Icon>
                      <FontAwesomeIcon icon={subRec.icon} />
                    </List.Icon>
                    <List.Content
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <span style={{ marginTop: 1, marginRight: 5 }}>
                        {subRec.title}
                      </span>
                      {subRec.types.variable && (
                        <Popup
                          trigger={
                            <strong className="budgetIndicator variableIndicator">
                              V
                            </strong>
                          }
                          content="Variable Item: allows for editing of spent column"
                        />
                      )}
                      {subRec.types.savings && (
                        <Popup
                          trigger={
                            <strong className="budgetIndicator savingsIndicator">
                              S
                            </strong>
                          }
                          content="Savings Item: monthly allocations are 
                                 added to total item savings level"
                        />
                      )}
                      {subRec.types.debt && (
                        <Popup
                          trigger={
                            <strong className="budgetIndicator debtIndicator">
                              D
                            </strong>
                          }
                          content="Debt Item: monthly contributions reduce the total
                                 debt owed amount"
                        />
                      )}
                      {subRec.types.preTax && (
                        <Popup
                          trigger={
                            <strong className="budgetIndicator preTaxIndicator">
                              P
                            </strong>
                          }
                          content="PreTax Item: monthly contributions from this item
                                 are calculated against gross income instead of net"
                        />
                      )}
                      <span
                        style={{
                          position: "absolute",
                          left: 218,
                          display: "inline-flex"
                        }}
                      >
                        <span>$</span>
                        <ClickToEdit
                          wrapperClass="editableBudgetLabel"
                          inputClass="editableBudgetInput"
                          textClass="editableBudgetText"
                          fieldAffected={{
                            sectionId: data.id,
                            headerId: rec.id,
                            itemId: subRec.id,
                            column: "budgeted"
                          }}
                          value={parseFloat(subRec.budgeted)
                            .toFixed(2)
                            .toString()}
                          endEditing={updateData}
                        />
                      </span>
                      <span
                        style={{
                          position: "absolute",
                          left: 368,
                          display: "inline-flex",
                          fontSize: "15px"
                        }}
                      >
                        <span>$</span>
                        {subRec.types.variable ? (
                          <ClickToEdit
                            wrapperClass="editableBudgetLabel"
                            inputClass="editableBudgetInput"
                            textClass="editableBudgetText"
                            fieldAffected={{
                              sectionId: data.id,
                              headerId: rec.id,
                              itemId: subRec.id,
                              column: "spent"
                            }}
                            value={parseFloat(subRec.spent)
                              .toFixed(2)
                              .toString()}
                            endEditing={updateData}
                          />
                        ) : (
                          parseFloat(subRec.spent).toFixed(2)
                        )}
                      </span>
                      <span style={{ position: "absolute", left: 518 }}>
                        ${parseFloat(subRec.leftover).toFixed(2)}
                      </span>
                      <span style={{ position: "absolute", left: 668 }}>
                        <Progress
                          className="budgetAtlasScoreProgress"
                          value={subRec.atlasScore}
                          progress="value"
                          size="small"
                          indicating
                        />
                      </span>
                      <span
                        style={{
                          float: "right",
                          paddingRight: 10,
                          display: "inline-flex",
                          marginLeft: "auto"
                        }}
                      >
                        <span>
                          {parseFloat(subRec.budgetedPercent).toFixed(1)}
                        </span>
                        <span>%</span>
                      </span>
                      <span style={{ float: "right", paddingRight: 1 }}>
                        <Button
                          circular
                          compact
                          size="small"
                          icon="remove"
                          style={{ background: "palevioletred" }}
                          className="hoverBtn"
                          onClick={() =>
                            deleteData({
                              title: subRec.title,
                              headerId: rec.id,
                              itemId: subRec.id
                            })
                          }
                        />
                      </span>
                      <span style={{ float: "right", paddingRight: 1 }}>
                        <Button
                          circular
                          compact
                          size="small"
                          icon="line graph"
                          style={{ background: "lightblue" }}
                          className="hoverBtn"
                          onClick={() =>
                            openCompareModal({
                              title: subRec.title,
                              budgeted: subRec.budgeted
                            })
                          }
                        />
                      </span>
                      <span style={{ float: "right", paddingRight: 1 }}>
                        <Button
                          circular
                          compact
                          size="small"
                          icon="idea"
                          style={{ background: "lightgreen" }}
                          className="hoverBtn"
                          onClick={() => openIdeaModal({})}
                        />
                      </span>
                    </List.Content>
                  </List.Item>
                  {rec.sections.length === i + 1 && (
                    <List.Item style={{ borderTop: "none" }}>
                      <List.Content style={{ textAlign: "left" }}>
                        <Button
                          icon
                          compact
                          fluid
                          className="budgetAddBtn"
                          onClick={() =>
                            addData({
                              position: rec.type,
                              unallocatedBudget: unallocatedBudget
                            })
                          }
                        >
                          <Icon name="plus" className="secondaryTheme" />
                        </Button>
                      </List.Content>
                    </List.Item>
                  )}
                </List>
              );
            })
          )
      }));

    return (
      <div>
        <Grid columns={3} divided style={{ marginBottom: 5 }}>
          <Grid.Column className="budgetExtraHeading">
            <h4 className="budgetExtraHeadingHeader">Leftover Budget Funds</h4>
            <h4 className="budgetExtraHeadingContent">
              ${data && parseFloat(unallocatedBudget).toFixed(2)}
            </h4>
          </Grid.Column>
          <Grid.Column className="budgetExtraHeading">
            <h4 className="budgetExtraHeadingHeader">
              Leftover Monthly Spending
            </h4>
            <h4 className="budgetExtraHeadingContent">
              ${data && parseFloat(unallocatedSpending).toFixed(2)}
            </h4>
          </Grid.Column>
          <Grid.Column className="budgetExtraHeading">
            <h4 className="budgetExtraHeadingHeader">Total Budget Score</h4>
            <h4 className="budgetExtraHeadingContent">77</h4>
          </Grid.Column>
        </Grid>
        <Menu secondary style={{ marginBottom: 0, marginTop: 0 }}>
          <Menu.Item
            name="Category"
            className="budgetListHeader"
            style={{ left: 0 }}
          />
          <Menu.Item
            name="Budgeted"
            className="budgetListHeader"
            style={{ left: 200 }}
          />
          <Menu.Item
            name="Spent"
            className="budgetListHeader"
            style={{ left: 350 }}
          />
          <Menu.Item
            name="Leftover"
            className="budgetListHeader"
            style={{ left: 500 }}
          />
          <Menu.Item
            name="AtlasScore"
            className="budgetListHeader"
            style={{ left: 650 }}
          />
        </Menu>
        <Accordion
          defaultActiveIndex={[0, 1, 2, 3]}
          panels={panels}
          exclusive={false}
          fluid
        />
      </div>
    );
  }
}

export default BudgetListContent;
