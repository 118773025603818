import { toastr } from "react-redux-toastr";
import axios from "axios";

export const compareChooser = (choice, authUID) => {
  return async () => {
    try {
      const payload = {
        choice: choice,
        authUID: authUID
      };

      axios
        .post(
          `https://us-central1-atlas-220300.cloudfunctions.net/compareChooser`,
          payload
        )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(error) {
          console.log(error);
        });
    } catch (error) {
      toastr.error("Oops", "Something went wrong");
      console.log("compareChooser threw an error");
    }
  };
};

export const updateBudgetData = (inputData, fieldToUpdate, authUID) => {
  return async () => {
    try {
      const payload = {
        val: inputData,
        fieldToUpdate: fieldToUpdate,
        authUID: authUID
      };

      axios
        .post(
          `https://us-central1-atlas-220300.cloudfunctions.net/updateBudgetNodeData`,
          payload
        )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(error) {
          console.log(error);
        });

      toastr.success("Success!", "Budget data has been updated");
    } catch (error) {
      toastr.error("Oops", "Something went wrong");
      console.log("updateBudgetData threw an error" + error);
    }
  };
};

export const removeBudgetRecord = (headerId, itemId, authUID) => {
  return async () => {
    try {
      const payload = {
        headerId: headerId,
        itemId: itemId,
        authUID: authUID
      };

      axios
        .post(
          `https://us-central1-atlas-220300.cloudfunctions.net/removeBudgetRecord`,
          payload
        )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(error) {
          console.log(error);
        });
    } catch (error) {
      toastr.error("Oops", "Something went wrong");
      console.log("removeBudgetRecord threw an error");
    }
  };
};

export const addBudgetRecord = (
  section,
  title,
  icon,
  budgeted,
  variable,
  preTax,
  savings,
  debt,
  authUID
) => {
  return async () => {
    try {
      const payload = {
        section: section,
        title: title,
        icon: icon,
        budgeted: budgeted,
        variable: variable,
        preTax: preTax,
        savings: savings,
        debt: debt,
        authUID: authUID
      };

      axios
        .post(
          `https://us-central1-atlas-220300.cloudfunctions.net/addBudgetRecord`,
          payload
        )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(error) {
          console.log(error);
        });
    } catch (error) {
      toastr.error("Oops", "Something went wrong");
      console.log("addBudgetRecord threw an error");
    }
  };
};

// START: Edit Income Modal Logic

export const addIncomeSource = authUID => {
  return async () => {
    try {
      const payload = {
        authUID: authUID
      };

      axios
        .post(
          `https://us-central1-atlas-220300.cloudfunctions.net/addIncomeSource`,
          payload
        )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(error) {
          console.log(error);
        });
    } catch (error) {
      toastr.error("Oops", "Something went wrong");
      console.log("addIncomeSource threw an error");
    }
  };
};

export const removeIncomeSource = (sourceId, authUID) => {
  return async () => {
    try {
      const payload = {
        sourceId: sourceId,
        authUID: authUID
      };

      axios
        .post(
          `https://us-central1-atlas-220300.cloudfunctions.net/addIncomeSource`,
          payload
        )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(error) {
          console.log(error);
        });
    } catch (error) {
      toastr.error("Oops", "Something went wrong");
      console.log("removeIncomeSource threw an error");
    }
  };
};

export const updateIncomeSourceData = (newData, type, sourceId, authUID) => {
  return async () => {
    try {
      const payload = {
        newData: newData,
        type: type,
        sourceId: sourceId,
        authUID: authUID
      };

      axios
        .post(
          `https://us-central1-atlas-220300.cloudfunctions.net/updateIncomeSource`,
          payload
        )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(error) {
          console.log(error);
        });
    } catch (error) {
      toastr.error("Oops", "Something went wrong");
      console.log("updateIncomeSourceData threw an error");
    }
  };
};

// END: Edit Income Modal Logic

// START: Savings Section Logic 

export const removeSavingsItem = (title, authUID) => {
  return async () => {
    try {
      const payload = {
        title: title,
        authUID: authUID
      }

      axios
        .post(
          `https://us-central1-atlas-220300.cloudfunctions.net/removeSavingsItem`,
          payload
        )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(error) {
          console.log(error);
        })
    } catch (error) {
      toastr.error("Oops", "Something went wrong")
      console.log("removeSaingsItem threw an error")
    }
  };
}

export const editSavingsItem = (title, value, authUID) => {
  return async () => {
    try {
      const payload = {
        title: title,
        value: value,
        authUID: authUID
      }

      axios
        .post(
          `https://us-central1-atlas-220300.cloudfunctions.net/editSavingsItem`,
          payload
        )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(error) {
          console.log(error);
        })
    } catch (error) {
      toastr.error("Oops", "Something went wrong")
      console.log("removeSaingsItem threw an error")
    }
  };
}

// START: Debt Section Logic 

export const removeDebtItem = (title, authUID) => {
  return async () => {
    try {
      const payload = {
        title: title,
        authUID: authUID
      }

      axios
        .post(
          `https://us-central1-atlas-220300.cloudfunctions.net/removeDebtItem`,
          payload
        )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(error) {
          console.log(error);
        })
    } catch (error) {
      toastr.error("Oops", "Something went wrong")
      console.log("removeSaingsItem threw an error")
    }
  };
}

export const editDebtItem = (title, value, authUID) => {
  return async () => {
    try {
      const payload = {
        title: title,
        value: value,
        authUID: authUID
      }

      axios
        .post(
          `https://us-central1-atlas-220300.cloudfunctions.net/editDebtItem`,
          payload
        )
        .then(function(res) {
          console.log(res);
        })
        .catch(function(error) {
          console.log(error);
        })
    } catch (error) {
      toastr.error("Oops", "Something went wrong")
      console.log("removeSaingsItem threw an error")
    }
  };
}

// END: Savings Section Logic 