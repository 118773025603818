import React, { Component } from "react";
import { Container } from "semantic-ui-react";
import { Route, Switch } from "react-router-dom";
import ProfileDashboard from "../../features/profile/ProfileDashboard/ProfileDashboard";
import NavBar from "../../features/nav/NavBar/NavBar";
import HomePage from "../../features/home/HomePage";
import BudgetDashboard from "../../features/budget/BudgetDashboard/BudgetDashboard";
import SettingsPage from "../../features/settings/Settings";
import ProSignUp from "../../features/proSignUp/ProSignUp";
import ModalManager from "../../features/modals/ModalManager";
import { library } from '@fortawesome/fontawesome-svg-core';
import { 
  faHome,
  faTint, 
  faLightbulb,
  faWifi,
  faWater,
  faTools,
  faLock,
  faParking,
  faTshirt,
  faTrashAlt,
  faHandHoldingUsd,
  faMoneyBillAlt,
  faUniversity,
  faShoppingCart,
  faGasPump,
  faDumbbell,
  faUtensils,
  faCar,
  faVideo,
  faMusic,
  faBan,
  faBuilding,
  faCut,
  faBroom,
  faSnowplow,
  faQuestion,
  faExclamationTriangle,
  faMobile,
  faBus,
  faUserMd,
  faCapsules,
  faBookDead,
  faBaby,
  faBabyCarriage,
  faSchool,
  faCarrot,
  faRandom,
  faToiletPaper,
  faUser,
  faSnowboarding,
  faGamepad,
  faBowlingBall,
  faTheaterMasks
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faHome,
  faTint,
  faLightbulb,
  faWifi,
  faWater,
  faTools,
  faLock,
  faParking,
  faTshirt,
  faTrashAlt,
  faHandHoldingUsd,
  faMoneyBillAlt,
  faUniversity,
  faShoppingCart,
  faGasPump,
  faDumbbell,
  faUtensils,
  faCar,
  faVideo,
  faMusic,
  faBan,
  faBuilding,
  faCut,
  faBroom,
  faSnowplow,
  faQuestion,
  faExclamationTriangle,
  faMobile,
  faBus,
  faUserMd,
  faCapsules,
  faBookDead,
  faBaby,
  faBabyCarriage,
  faSchool,
  faCarrot,
  faRandom,
  faToiletPaper,
  faUser,
  faSnowboarding,
  faGamepad,
  faBowlingBall,
  faTheaterMasks
);

class App extends Component {
  render() {
    return (
      <div>
        <ModalManager />
        <Switch>
          <Route exact path="/" component={HomePage} />
        </Switch>

        <Route
          path="/(.+)"
          render={() => (
            <div>
              <NavBar />
              <Container className="main">
                <Switch>
                  <Route path="/profile" component={ProfileDashboard} />
                  <Route path="/budget" component={BudgetDashboard} />
                  <Route path="/settings" component={SettingsPage} />
                  <Route path="/proSignUp" component={ProSignUp} />
                </Switch>
              </Container>
            </div>
          )}
        />
      </div>
    );
  }
}

export default App;
