import React, { Component } from "react";
import { Grid } from 'semantic-ui-react'

class ProfileListItemContent extends Component {
  render() {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={13}>
            Additional Profile List Information.
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default ProfileListItemContent;
