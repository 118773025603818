import React, { Component } from "react";
import { Form, Header, Grid, Image, Button } from "semantic-ui-react";
import { reduxForm } from "redux-form";
import {
  composeValidators,
  combineValidators,
  isRequired,
  hasLengthGreaterThan
} from "revalidate";
import { Field } from "redux-form";
import TextInput from "../../../app/common/form/TextInput";
import SocialCreateAccount from "../SocialCreateAccount/SocialCreateAccount";

const validate = combineValidators({
  email: isRequired({ message: "Email is required" }),
  password: composeValidators(
    isRequired({ message: "Password is required" }),
    hasLengthGreaterThan(4)({
      message: "Password must be greater than 4 characters"
    })
  )(),
  rePassword: isRequired({ message: "Must re-type password" })
});

export class StepAccountInfo extends Component {
  render() {
    const {
      invalid,
      createStandardAccount,
      createAlternativeAccount,
      submitting,
      pristine,
    } = this.props;
    return (
      <Grid className="registerFormContent">
        <Grid.Column width={16} className="registerFormTopContent">
          <Image
            src="/assets/accountInfoBanner.png"
            fluid
            className="registerFormImage"
          />
          <p className="registerSectionDescriptionText">
            Create your account with either a traditional username and password,
            or sign on with facebook or google authentication.
          </p>
        </Grid.Column>
        <Grid.Column width={16}>
          <Form
            error
            style={{ padding: 21 }}
            onSubmit={this.props.handleSubmit(createStandardAccount)}
          >
            <Header as="h3" content="Email" floated="left" />
            <Field
              name="email"
              type="text"
              component={TextInput}
              placeholder="your@email.com"
            />
            <Header as="h3" content="Password" floated="left" />
            <Field
              name="password"
              type="text"
              component={TextInput}
              placeholder=""
            />
            <Header as="h3" content="Re-Type Password" floated="left" />
            <Field
              name="rePassword"
              type="text"
              component={TextInput}
              placeholder=""
            />
            <Button
              type="submit"
              className="registerFormBtn"
              disabled={invalid || submitting || pristine}
              style={{ float: "left" }}
            >
              Create Standard Account
            </Button>
            <SocialCreateAccount
              style={{ float: "left" }}
              clickedItem={createAlternativeAccount}
            />
          </Form>
        </Grid.Column>
      </Grid>
    );
  }
}

export default reduxForm({ form: "accountInfoForm", validate })(
  StepAccountInfo
);
