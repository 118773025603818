import React, { Component } from "react";
import {
  Segment,
  Icon,
  Grid,
  Popup,
  Button,
  GridColumn
} from "semantic-ui-react";

class ProfileListItem extends Component {
  render() {
    return (
      <Grid>
        <Grid.Row>
          <GridColumn width={13}>
            <Segment.Group>
              <Segment color="green">
                <Grid divided>
                  <Grid.Row>
                    <Grid.Column width={5}>
                      <Icon name="dropdown" />
                      Profile List Item Name
                    </Grid.Column>
                    <Grid.Column width={10}>
                      <span>$14,556 </span>
                      <span style={{ color: "green" }}>(+2.03%)</span>
                      <Icon name="arrow up" color="green" />
                    </Grid.Column>
                    <Popup
                      trigger={
                        <Grid.Column width={1} color="green" floated="right">
                          88
                        </Grid.Column>
                      }
                      content="How you score against those in similar age, location, income, and occupation. Scored out of 100."
                    />
                  </Grid.Row>
                </Grid>
              </Segment>
            </Segment.Group>
          </GridColumn>
          <GridColumn width={3}>
            <Button className="improveMyScoreBtn">Improve My Rating</Button>
          </GridColumn>
        </Grid.Row>
      </Grid>
    );
  }
}

export default ProfileListItem;
