import React from "react";

const Settings = () => {
  return (
    <div>
      <h1>Settings Page</h1>
    </div>
  );
};

export default Settings;
