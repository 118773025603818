import React, { Component } from "react";
import { Grid, GridRow, Progress } from "semantic-ui-react";

class BudgetVariableAllocations extends Component {
  determineProgressColor = (spent, budgeted) => {
    const percent = spent / budgeted;
    var color = "green";
    switch (true) {
      case percent < 0.74:
        color = "green";
        break;
      case percent < 0.895:
        color = "yellow";
        break;
      default:
        color = "red";
        break;
    }
    return color;
  };

  createAllocationView = data => {
    // have to have empty zeroth object for reduce to count all needed sections
    data.content.unshift({});
    const alloc_hash = data.content.reduce(function(map, rec) {
      map[rec.type] = rec.sections.filter(sec => sec.types.variable === true);
      return map;
    });
    data.content.shift();

    let rows = [];
    for (const [index, [key, sections]] of Object.entries(Object.entries(alloc_hash))) {
      if (key !== "taxes"  && key !== "generalLiving") {
        rows.push(
          <GridRow key={parseInt(index)}>
            <h2>
              {key.replace(/([A-Z])/g, " $1").replace(/^./, function(str) {
                return str.toUpperCase();
              })}
            </h2>
          </GridRow>
        );
      } else if (key === "generalLiving") {
        rows.push(
          <GridRow key={parseInt(index)}>
            <h2>Necessities</h2>
          </GridRow>
        )
      }
      sections.forEach(sec => {
        rows.push(
          <GridRow key={(parseInt(index) + 100) * 100 + sec.id}>
            <h5 className="variableAllocationsItemTitle">{sec.title}</h5>
            <div className="variableAllocationsItemProgress">
              <span className="variableAllocationsProgressItem">
                ${sec.spent}
              </span>
              <Progress
                className="variableAllocationsProgress"
                title="Monthly Allocated"
                value={sec.spent}
                total={sec.budgeted}
                progress="percent"
                precision={1}
                color={this.determineProgressColor(sec.spent, sec.budgeted)}
                size="small"
              />
              <span className="variableAllocationsProgressItem">
                ${sec.budgeted}
              </span>
            </div>
          </GridRow>
        );
      });
    }
    return rows;
  };

  render() {
    const { data } = this.props;
    return <Grid>{this.createAllocationView(data)}</Grid>;
  }
}

export default BudgetVariableAllocations;
