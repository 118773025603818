import React, { Component } from "react";
import { Grid, Image, Header, Progress, Popup } from "semantic-ui-react";
import { USDConverter } from "../../../app/common/util/Currency";
import { Line } from "react-chartjs-2";

class ProfileHeader extends Component {
  render() {
    const { overview, goals, archive } = this.props;

    let currentYear = new Date().getFullYear();

    // classic javascript lul
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    let monthsBackArr = [12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0];
    let yearDataLabels = monthsBackArr.map(numMonth => {
      let today = new Date();
      let date = today;
      date.setMonth(today.getMonth() - numMonth);
      let yearStr =
        "'" +
        date.getFullYear().toString()[2] +
        date.getFullYear().toString()[3];
      return monthNames[date.getMonth()] + " " + yearStr;
    });
    let youNW = yearDataLabels.map(dateStr => {
      let today = new Date();
      let content = dateStr.split(" ");
      let year = "20" + content[1].slice(-2);
      let month = content[0].toLowerCase();
      if (
        monthNames[today.getMonth()].toLowerCase() === month &&
        today.getFullYear().toString() === year
      ) {
        return overview.netWorth;
      } else {
        if (archive[year] === undefined || archive[year][month] === undefined) {
          return 0;
        }
        return archive[year][month].netWorth;
      }
    })

    const nwData = {
      labels: yearDataLabels,
      datasets: [
        {
          label: "You",
          fill: false,
          lineTension: 0.1,
          backgroundColor: "rgba(75,192,192,0.4)",
          borderColor: "rgba(75,192,192,1)",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "rgba(75,192,192,1)",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(75,192,192,1)",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: youNW
        },
        {
          label: "Direct Compare",
          fill: false,
          lineTension: 0.1,
          backgroundColor: "rgba(255,69,0,0.4)",
          borderColor: "rgba(255,69,0,1)",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "rgba(225,69,0,1)",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(255,69,0,1)",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: youNW.map(val => val * (Math.random() < 0.5 ? 0.77 : 0.43))
        },
        {
          label: "Goal",
          fill: false,
          lineTension: 0.1,
          backgroundColor: "rgba(255,223,0,0.4)",
          borderColor: "rgba(255,223,0,1)",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "rgba(255,223,255,1)",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(255,223,255,1)",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: youNW.map(val => val * (Math.random() < 0.5 ? 0.89 : 0.59))
        }
      ]
    };

    let nwOptions = {
      legend: {
        display: true,
        position: "bottom"
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              callback: function(label, index, labels) {
                return "$" + label;
              }
            }
          }
        ]
      }
    };

    return (
      <Grid verticalAlign="middle" textAlign="center">
        <Grid.Row centered columns={1} style={{ paddingTop: "0px" }}>
          <Grid.Column>
            <Image
              className="imgLeft"
              circular
              src="https://randomuser.me/api/portraits/men/17.jpg"
            />
            <div className="profileTopHeader">
              <Header as="h2">{overview.name}</Header>
              <span>
                {overview.age} Year Old {overview.occupation},{" "}
                {overview.location}
              </span>
              <span className="profilePageItemHeaderTopText">
                Overall Atlas Score
              </span>
              <Popup
                trigger={
                  <Progress
                    value="92"
                    total="100"
                    color="green"
                    active
                    progress="ratio"
                    className="profileAtlasScore"
                  />
                }
                content="How your overall profile ranks against those of similar age, location, income, and occupation"
              />
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered columns={4} className="profileHeaderSection">
          <Grid.Column>
            <Header as="h3" className="profilePageItemHeader">
              Income
            </Header>
            <Header as="h1" className="profilePageItemHeaderVal">
              {USDConverter(overview.grossIncome, false)}
            </Header>
            <span className="profilePageItemHeaderGoalText">
              2019 Goal Progress
            </span>
            <Popup
              trigger={
                <Progress
                  value={overview.grossIncome}
                  total={goals.income[currentYear]}
                  color="yellow"
                  active
                  progress="ratio"
                />
              }
              content="How you are progressing against this years income goal"
            />
          </Grid.Column>
          <Grid.Column>
            <Header as="h3" className="profilePageItemHeader">
              Savings
            </Header>
            <Header as="h1" className="profilePageItemHeaderVal">
              {USDConverter(overview.savings, false)}
            </Header>
            <span className="profilePageItemHeaderGoalText">
              2019 Goal Progress
            </span>
            <Popup
              trigger={
                <Progress
                  value={overview.savings}
                  total={goals.savings[currentYear]}
                  color="yellow"
                  active
                  progress="ratio"
                />
              }
              content="How you are progressing against this years goal"
            />
          </Grid.Column>
          <Grid.Column>
            <Header as="h3" className="profilePageItemHeader">
              Assets
            </Header>
            <Header as="h1" className="profilePageItemHeaderVal">
              {USDConverter(overview.assets, false)}
            </Header>
            <span className="profilePageItemHeaderGoalText">
              2019 Goal Progress
            </span>
            <Popup
              trigger={
                <Progress
                  value={overview.assets}
                  total={goals.assets[currentYear]}
                  color="yellow"
                  active
                  progress="ratio"
                />
              }
              content="How you are progressing against this years goal"
            />
          </Grid.Column>
          <Grid.Column>
            <Header as="h3" className="profilePageItemHeader">
              Debts
            </Header>
            <Header as="h1" className="profilePageItemHeaderValNegative">
              {USDConverter(overview.debts, false)}
            </Header>
            <span className="profilePageItemHeaderGoalText">
              2019 Goal Progress
            </span>
            <Popup
              trigger={
                <Progress
                  value={overview.debts}
                  total={goals.debts[currentYear]}
                  color="yellow"
                  active
                  progress="ratio"
                />
              }
              content="How you are progressing against this years goal"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1} className="profilePageNetWorthRow">
          <Grid.Column className="profilePageNetWorthCol">
            <Header as="h1" className="profilePageNetWorthHeader">
              <Header.Content className="profilePageNetWorth">
                {USDConverter(overview.netWorth, false)}
              </Header.Content>
              <span className="profilePageNetWorthText">Net Worth</span>
            </Header>
            <Line data={nwData} options={nwOptions} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default ProfileHeader;
