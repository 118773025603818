import React, { Component } from "react";
import { Header, Grid, Menu, Popup, Checkbox, Icon } from "semantic-ui-react";

class BudgetHeaderContent extends Component {
  render() {
    const monthNames = [
      "JANUARY",
      "FEBRUARY",
      "MARCH",
      "APRIL",
      "MAY",
      "JUNE",
      "JULY",
      "AUGUST",
      "SEPTEMBER",
      "OCTOBER",
      "NOVEMBER",
      "DECEMBER"
    ];

    let date = new Date();
    let year = date.getFullYear();
    let month = monthNames[date.getMonth()];

    const { general, compareChange, openIncomeModal } = this.props;
    return (
      <div>
        <Grid>
          <Grid.Column width={16} style={{ paddingBottom: 0, paddingTop: 0 }}>
            <Menu horizontal="true" secondary>
              <Popup
                trigger={
                  <Menu.Item style={{ paddingBottom: 0 }}>
                    <Header as="h2">
                      {month}
                      <Header.Subheader>{year}</Header.Subheader>
                    </Header>
                  </Menu.Item>
                }
                content="The current month your budget is being tracked on"
              />
              <Popup
                trigger={
                  <Menu.Item
                    style={{ paddingBottom: 0 }}
                    onClick={() =>
                      openIncomeModal({
                        gross: general.grossIncome,
                        net: general.netIncome,
                        incomeSources: general.incomeSources
                      })
                    }
                  >
                    <Header as="h2" style={{ color: "green" }}>
                      <Icon
                        name="edit"
                        style={{
                          float: "right",
                          fontSize: "0.5em"
                        }}
                      />
                      ${(general && general.grossIncome / 12).toFixed(0)}
                      <Header.Subheader>Gross Income</Header.Subheader>
                    </Header>
                  </Menu.Item>
                }
                content="Your income before taxes. Click to edit."
              />
              <Popup
                trigger={
                  <Menu.Item
                    style={{ paddingBottom: 0 }}
                    onClick={() =>
                      openIncomeModal({
                        gross: general.grossIncome,
                        net: general.netIncome,
                        incomeSources: general.incomeSources
                      })
                    }
                  >
                    <Header as="h2" style={{ color: "green" }}>
                      <Icon
                        name="edit"
                        style={{
                          float: "right",
                          fontSize: "0.5em",
                          marginLeft: "10px"
                        }}
                      />
                      ${general && (general.netIncome / 12).toFixed(0)}
                      <Header.Subheader>Net Income</Header.Subheader>
                    </Header>
                  </Menu.Item>
                }
                content="Your income after taxes. Click to edit."
              />
              <Menu.Item position="right" style={{ paddingBottom: 0 }}>
                <Popup
                  trigger={
                    <Menu.Item>
                      <Header as="h2" style={{ color: "green" }}>
                        {general &&
                          parseFloat(general.percentHousing).toFixed(1)}
                        %<Header.Subheader>Housing</Header.Subheader>
                      </Header>
                    </Menu.Item>
                  }
                  content="Percent of your income going to rent, utilities, insurance, etc"
                />
                <Popup
                  trigger={
                    <Menu.Item>
                      <Header as="h2" style={{ color: "green" }}>
                        {general &&
                          parseFloat(general.percentGeneralLiving).toFixed(1)}
                        %<Header.Subheader>Necessities</Header.Subheader>
                      </Header>
                    </Menu.Item>
                  }
                  content="Percent of your income going to groceries, gas, haircuts, gyms, etc"
                />
                <Popup
                  trigger={
                    <Menu.Item>
                      <Header as="h2" style={{ color: "green" }}>
                        {general &&
                          parseFloat(general.percentInvestments).toFixed(1)}
                        %<Header.Subheader>Investments</Header.Subheader>
                      </Header>
                    </Menu.Item>
                  }
                  content="Percent of your income going to investments and savings"
                />
                <Popup
                  trigger={
                    <Menu.Item>
                      <Header as="h2" style={{ color: "green" }}>
                        {general &&
                          parseFloat(general.percentIndulgences).toFixed(1)}
                        %<Header.Subheader>Indulgences</Header.Subheader>
                      </Header>
                    </Menu.Item>
                  }
                  content="Percent of your income going to fun, subscriptions, vacation, etc"
                />
                <Popup
                  trigger={
                    <Menu.Item>
                      <Header as="h2" style={{ color: "orange" }}>
                        {general &&
                          parseFloat(general.percentTaxes).toFixed(1)}
                        %<Header.Subheader>Taxes</Header.Subheader>
                      </Header>
                    </Menu.Item>
                  }
                  content="Percent of your income going to taxes"
                />
              </Menu.Item>
            </Menu>
          </Grid.Column>
          <Grid.Column width={16} style={{ paddingTop: 0 }}>
            <Menu horizontal="true" secondary>
              <Menu.Item position="right">
                <Checkbox
                  toggle
                  label="Compare to Net"
                  onChange={compareChange}
                />
              </Menu.Item>
            </Menu>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default BudgetHeaderContent;
