import React, { Component } from "react";
import { Modal, Divider, List } from "semantic-ui-react";
import { connect } from "react-redux";

import { closeModal } from "./modalActions";

const actions = { closeModal };

class RoadmapModal extends Component {
  render() {
    return (
      <Modal size="small" open={true} onClose={this.props.closeModal}>
        <Modal.Header>Roadmap</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <h3>Upcoming Releases</h3>
            <Divider />
            <h4>0.3.5:</h4>
            <List as="ul" className="roadmapList">
              <List.Item as="li">
                Add compare module functionality 
              </List.Item>
              <List.Item as="li">
                Create all default compare values  
              </List.Item>
              <List.Item as="li">
                Finalize all compare values and possible budget nodes 
              </List.Item>
            </List>
            <h4>0.3.6:</h4>
            <List as="ul" className="roadmapList">
              <List.Item as="li">
                Update landing page css and general layout 
              </List.Item>
              <List.Item as="li">
                Update sign up page to look and feel better 
              </List.Item>
            </List>
            <h4>0.3.7:</h4>
            <List as="ul" className="roadmapList">
              <List.Item as="li">
                Add missing budget features 
              </List.Item>
              <List.Item as="li">
                Update all budget inputs to change values properly 
              </List.Item>
            </List>
            <h4>0.3.8:</h4>
            <List as="ul" className="roadmapList">
              <List.Item as="li">
                Fix all minor errors and UI/UX issues 
              </List.Item>
            </List>
            <h4>0.4.0:</h4>
            <List as="ul" className="roadmapList">
              <List.Item as="li">
                Add compare page to check any user inputs against yourself and others
              </List.Item>
            </List>

            <h3>Upcoming Major Releases</h3>
            <Divider />
           <h4>0.5.0: Exponention</h4>
            <p>
              The Exponention release will include a robust scoring system using
              the Plutus engine to evaluate all financal aspects of the user to
              see what is being done well and what is being done bad. The
              scoring system will evaluate user inputs against those in similar
              categorizations.
            </p>
            <h4>0.6.0: Systentic</h4>
            <p>
              The Systentic release will include a wide array of visual tools
              including graphs and charts to help users see how they compare
              against others as well as tracking their overall finances.
            </p>
            <h4>0.7.0: Green Angel</h4>
            <p>
              The Green Angel release will include a meriad of helping features
              that allow users to see how to improve their finance standing.
            </p>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

export default connect(
  null,
  actions
)(RoadmapModal);
