import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { reduxForm } from "redux-form";
import StepZilla from "react-stepzilla";
import StepAccountInfo from "./StepAccountInfo";
import StepPersonalInfo from "./StepPersonalInfo";
import { registerUser } from "../authActions";

const actions = {
  registerUser
};

class RegisterForm extends Component {
  constructor(props) {
    super(props);
    this.stepZilla = React.createRef();
    this.state = {
      error: null,
      email: null,
      password: null,
      useFacebookLogin: false,
      useGoogleLogin: false,
      fullname: null,
      sex: null,
      date: null,
      location: null,
      occupation: null,
      employer: null,
      experience: null,
      grossIncome: null,
      netIncome: null,
   };
  }

  sendLoadNextStep = authAdditions => {
    this.setState(authAdditions);
    if (this.stepZilla.current) {
      return this.stepZilla.current.next();
    }
    return;
  };

  registerNewUser = () => {
    this.props
      .registerUser(this.state, this.props.history)
      .catch(err => this.setState({ error: err.errors._error.message }))
  }

  createStandardAccount = authAdditions => {
    this.setState(authAdditions, function() {
      this.registerNewUser();
    });
  }

  createAlternativeAccount = clickedItem => {
    switch (clickedItem) {
      case "facebook":
        this.setState({ useFacebookLogin: true }, function() {
          this.registerNewUser();
        });
        break;
      case "google":
        this.setState({ useGoogleLogin: true }, function() {
          this.registerNewUser();
        });
        break;
      default:
        break;
    }
 };

  createSteps = () => {
    return [
      {
        name: "General Info",
        component: (
          <StepPersonalInfo
            loadNextStep={this.sendLoadNextStep}
          />
        )
      },
      {
        name: "Account Info",
        component: (
          <StepAccountInfo
            createStandardAccount={this.createStandardAccount}
            createAlternativeAccount={this.createAlternativeAccount}
          />
        )
      }
    ];
  };

  render() {
    return (
      <div className="step-progress">
        <StepZilla
          ref={this.stepZilla}
          steps={this.createSteps()}
          showNavigation={false}
          stepsNavigation={false}
        />
      </div>
    );
  }
}

export default withRouter(
  connect(
    null,
    actions
  )(reduxForm({ form: "registerForm" })(RegisterForm))
);
