import React, { Component } from "react";
import { Grid, Menu } from "semantic-ui-react";
import { NavLink, withRouter } from "react-router-dom";

class BudgetNav extends Component {
  render() {
    return (
      <Grid.Column width={16}>
        <Menu horizontal="true" pointing secondary>
          <Menu.Item as={NavLink} to="/budget/budget">
            Budget
          </Menu.Item>
          <Menu.Item as={NavLink} to="/budget/variable_allocations">
            Variable Allocations 
          </Menu.Item>
          <Menu.Item as={NavLink} to="/budget/savings">
            Savings
          </Menu.Item>
          <Menu.Item as={NavLink} to="/budget/debt">
            Debt
          </Menu.Item>
        </Menu>
      </Grid.Column>
    );
  }
}

export default withRouter(BudgetNav);
