import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { reduxForm, Field } from "redux-form";
import { Form, Button, Grid, Label, Divider } from "semantic-ui-react";
import { login, socialLogin } from "../authActions";
import TextInput from "../../../app/common/form/TextInput";
import SocialLogin from "../SocialLogin/SocialLogin";

const actions = {
  login,
  socialLogin
};

class LoginForm extends Component {
  state = {
    error: null
  };

  onFormSubmit = values => {
    this.props
      .login(values, this.props.history)
      .catch(err => this.setState({ error: err.errors._error }));
  };

  onSocialLogin = user => {
    this.props
      .socialLogin(user, this.props.history)
      .catch(err => this.setState({ error: err.errors._error }))
  }

  render() {
    const { error } = this.state;
    return (
      <Grid>
        <Grid.Column width={16}>
          <Form
            size="large"
            onSubmit={this.props.handleSubmit(this.onFormSubmit)}
          >
            <Field
              name="email"
              type="text"
              component={TextInput}
              placeholder="email"
            />
            <Field
              name="password"
              type="text"
              component={TextInput}
              placeholder="Password"
            />
            {error && (
              <Label basic color="red">
                {error}
              </Label>
            )}
            <Button className="loginBtn">Login</Button>
            <Divider horizontal>OR</Divider>
            <SocialLogin socialLogin={this.onSocialLogin}/>
          </Form>
        </Grid.Column>
      </Grid>
    );
  }
}

export default withRouter(
  connect(
    null,
    actions
  )(reduxForm({ form: "loginForm" })(LoginForm))
);
