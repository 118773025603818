const contentHash = {
  headerContent: [
    {
      id: 1,
      title: "Demographics",
      age: 21,
      sex: "male",
      location: "Tucky, KY"
    },
    {
      id: 2,
      title: "Profession",
      jobTitle: "Event Coordinator",
      employer: "Tucky Tuck",
      experience: "5 years",
      preTaxIncome: 62000,
      postTaxIncome: 44000
    },
    {
      id: 3,
      title: "Investments",
      highRisk: "blah blah",
      mediumRisk: "blah blah",
      lowRisk: "blah blah"
    },
    {
      id: 4,
      title: "Retirement",
      "401k": 415000,
      RothIRA: 61000
    }
  ],
  budgetContent: {
    grossIncome: 63000,
    netIncome: 43068,
    weekSections: {},
    monthSections: [
      {
        id: 1,
        title: "Housing",
        type: "housing",
        costTotal: 100.0,
        percentTotal: 28.3,
        sections: [
          {
            id: 1,
            title: "Rent",
            costTotal: 60.0,
            percentTotal: 60.0,
            compareScore: 67
          },
          {
            id: 2,
            title: "Electricity",
            costTotal: 40.0,
            percentTotal: 40.0,
            compareScore: 19
          }
        ]
      },
      { id: 2, title: "General Living", sections: [] },
      { id: 3, title: "Investments", sections: [] },
      { id: 4, title: "Indulgences", sections: [] }
    ],
    yearSections: {}
  }
};

export default contentHash;
