import React, { Component } from "react";
import { Modal } from "semantic-ui-react";
import { connect } from "react-redux";

import { closeModal } from "./modalActions";

const actions = { closeModal };

class BudgetIdeaModal extends Component {
  render() {
    return (
      <Modal size="large" open={true} onClose={this.props.closeModal}>
        <Modal.Header>How To Improve (WORK IN PROGRESS)</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            This screen will show ideas on how to improve this nodes atlas score
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

export default connect(
  null,
  actions
)(BudgetIdeaModal);
